function getNearestMonday() {
    var now = new Date();
    var dayOfWeek = now.getDay(); // 0-6, 0 is Sunday, 1 is Monday, etc.

    if (dayOfWeek === 1) { // If it's Monday
        return now;
    } else if (dayOfWeek === 0) { // If it's Sunday
        var diff = 1; // Next day is Monday
    } else {
        // Find out the difference to next Monday
        var diff = 8 - dayOfWeek; 
    }

    now.setDate(now.getDate() + diff);
    return now;
}

const cohorts = {
    'b0481b27': {
        date: new Date('2023-01-16'),
        name: 'Cohort 1'
    },
    '5d1ed98e': {
        date: new Date('2023-02-06'),
        name: 'Cohort 2'
    },
    '7318b848': {
        date: new Date('2023-03-06'),
        name: 'Cohort 3'
    },
    'd395503c': {
        date: new Date('2023-04-03'),
        name: 'Cohort 4'
    },
    '227ed18c': {
        date: new Date('2023-05-01'),
        name: 'Cohort 5'
    },
    '951b3184': {
        date: new Date('2023-06-05'),
        name: 'Cohort 6'
    },
    'c573de1a': {
        date: new Date('2023-07-03'),
        name: 'Cohort 7'
    },
    'open': {
        date: getNearestMonday(),
        name: 'Cohort 8'
    },
}

const defaultDate = getNearestMonday()

export {
    cohorts,
    defaultDate
}