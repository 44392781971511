import React, { useEffect, useState } from "react";
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import parse from "html-react-parser";
import { INITIAL_EVENTS_FULL, INITIAL_EVENTS_PART, setTodayStr, addDays, reduceDays, formatDate } from "./event-utils";
import { cohorts, defaultDate} from "./cohortDates";
import "./index.css";
import { useParams } from 'react-router-dom';


function renderEventContent(eventInfo) {
    return (
        <>
            <b>{eventInfo.event.title}</b>
        </>
    );
}

function getUrl(url) {
    if (url.includes('b0481b27')) {
      return url.replace('b0481b27', 'docs');
    } else if (url.includes('5d1ed98e')) {
      return url.replace('5d1ed98e', 'docs');
    } else if (url.includes('7318b848')) {
      return url.replace('7318b848', 'docs');
    } else if (url.includes('d395503c')) {
      return url.replace('d395503c', 'docs');
    } else {
      return url;
    }
  }

function DemoApp({isDemo}) {
    const [showReset, setShowReset] = useState(false)
    const [eventObj, setEventObj] = useState({});
    const [partTime, setTime] = useState(true);
    const [isDateSet, setDate] = useState(false);
    const [isLate, setLate] = useState(localStorage.getItem("calendar-late") == "true");
    const { cohortCode } = useParams();
    

    useEffect(() => {
        setTodayStr(cohortCode)
        setDate(true)
        let isModifiedCalendar = !!localStorage.getItem('part-eventData') || !!localStorage.getItem('full-eventData')
        setShowReset(isModifiedCalendar)
    }, [])

    if (!isDateSet) {
        return (
            <Box sx={{ display: 'flex' }}>
                <CircularProgress />
            </Box>
        )
    }

    function handleResetClick(){
        localStorage.removeItem("part-eventData")
        localStorage.removeItem("full-eventData");
        localStorage.removeItem("calendar-late");
        setLate(false)
        setShowReset(false);
    }

  

    function renderSidebar() {
        return (
            <div className="demo-app-sidebar">
                {
                    <div className="schedule">
                        <button
                            onClick={() => setTime(true)}
                            className={`scheduleButton ${partTime ? "btnActive" : ""}`}
                        >
                            Part Time
                        </button>
                        <button
                            onClick={() => setTime(false)}
                            className={`scheduleButton ${partTime ? "" : "btnActive"}`}
                        >
                            Full Time
                        </button>
                        <p>
                            You're viewing the <b>{partTime ? "Part-Time" : "Full-Time"}</b> Learning
                            Schedule
                        </p>
                        <i>
                            *Takes approximately <b>{partTime ? "24 weeks" : "12 weeks"}</b> to
                            finish
                        </i>
                        {/* {
                            !isDemo && (
                            <FormGroup>
                                <FormControlLabel className="lazyText" control={<Checkbox onClick={handleLateJoiners} checked={isLate} />} label="I'm starting late. Give me 7 extra days." />
                            </FormGroup>    
                            )
                        } */}
                    </div>
                }
                <div className="demo-app-sidebar-section">
                    {eventObj.extendedProps ? (
                        <>
                            <h3>{eventObj.title}</h3>
                            <p>{eventObj.extendedProps.desc}</p>
                            {eventObj.extendedProps?.guides?.length ? (
                                <ul>
                                    {eventObj.extendedProps?.guides.map((g) => {
                                        return parse(g);
                                    })}
                                </ul>
                            ) : (
                                ""
                            )}
                            <div>
                                {eventObj.extendedProps?.activities.length ? (
                                    <>
                                        <h2>Activities to complete</h2>
                                        <ul>
                                            {eventObj.extendedProps.activities.map((a) => {
                                                return (
                                                    <li>
                                                        <a href={a.url} target="_blank">
                                                            <p>{a.name}</p>
                                                        </a>
                                                    </li>
                                                );
                                            })}
                                        </ul>
                                    </>
                                ) : (
                                    ""
                                )}
                                {eventObj.extendedProps?.assignments.length ? (
                                    <>
                                        {eventObj.extendedProps?.isGraded ? (
                                            <div>
                                                <h2>🌟 Graded Assignment:</h2>
                                                <p>
                                                    Your assignment will be viewed by a UX/UI Open team member. You
                                                    will receive a badge upon completion that you can share on social
                                                    media
                                                </p>
                                            </div>
                                        ) : (
                                            <h2>Assignments:</h2>
                                        )}
                                        <ul>
                                            {eventObj.extendedProps.assignments.map((a) => {
                                                return (
                                                    <li>
                                                        <a href={getUrl(a.url)} target="_blank">
                                                            <p>{a.name}</p>
                                                        </a>
                                                    </li>
                                                );
                                            })}
                                        </ul>
                                    </>
                                ) : (
                                    ""
                                )}
                            </div>
                            <a href={getUrl(eventObj.extendedProps.url)} target="_blank">
                                <button className="moduleBtn">
                                    {"View Lesson"}
                                </button>
                            </a>
                        </>
                    ) : (
                        <div>
                           {
                               isDemo ? (
                                   <>
                                <p>This is a demo preview of the calendar.</p>
                                <p>Each coloured tile is a lesson in the bootcamp.</p>
                                <div className="previewContainer">
                                <span className="preview gold"></span> = Lessons with a self-evaluated Assignment
                                </div>
                                <div className="previewContainer">
                                <span className="preview normal"></span> = Read only lesson
                                </div>
                                <div className="previewContainer">
                                <span className="preview graded"></span> =  Lesson with graded Assignment
                                </div>
                                <div className="previewContainer">
                                <span className="preview mentor"></span> = Live mentor calls on ADPList
                                </div>
                                <div className="previewContainer">
                                <span className="preview green"></span> = Rest days
                                </div>
                                <div className="previewContainer">
                                <span className="preview optional"></span> = Optional topics
                                </div>
                                <p>You are free to adjust the schedule as per your own comfort</p>
                                </>
                               ) : (
                                <>   
                                <p>Please click on an event to view details</p>
                                <p>Each coloured tile is a lesson in the bootcamp.</p>
                                <div className="previewContainer">
                                <span className="preview gold"></span> = Lessons with a self-evaluated Assignment
                                </div>
                                <div className="previewContainer">
                                <span className="preview normal"></span> = Read only lesson
                                </div>
                                <div className="previewContainer">
                                <span className="preview graded"></span> =  Lesson with graded Assignment
                                </div>
                                <div className="previewContainer">
                                <span className="preview mentor"></span> = Live mentor calls on ADPList
                                </div>
                                <div className="previewContainer">
                                <span className="preview green"></span> = Rest days
                                </div>
                                <div className="previewContainer">
                                <span className="preview optional"></span> = Optional topics
                                </div>
                                <p>You are free to adjust the schedule as per your own comfort. Just drag the events</p>
                                {
                    showReset && (
                        <button
                            onClick={handleResetClick}
                            className={`scheduleButton ${partTime ? "btnActive" : ""} reset`}
                        >
                           Reset Calendar
                        </button>
                    )
                }
                                </>
                               )
                           }
                        </div>
                    )}
                </div>
               
            </div>
        );
    }

    function handleDateSelect() {
        if (!isDemo) {
            setEventObj({});
        }
    
    }

    function handleEventClick(clickInfo) {
        if (!isDemo) {
            setEventObj(clickInfo.event);
        }
        
    };

    function getFinalEventData(){
        let partEvents = JSON.parse(localStorage.getItem('part-eventData')) || INITIAL_EVENTS_PART
        let fullEvents = JSON.parse(localStorage.getItem('full-eventData')) || INITIAL_EVENTS_FULL

        return partTime ? partEvents: fullEvents;
    }

  

    async function handleLateJoiners(){
        let cohortNumber = window.location.pathname.split('/')[1]
        if (!cohortNumber) {
            return;
        }
        setShowReset(true)
        setLate(!isLate)
        localStorage.setItem("calendar-late", !isLate);
        let chooseData = getFinalEventData()
        let eventsClone = JSON.parse(JSON.stringify(chooseData))
        let newEvents = eventsClone.map((e) => {
            if (!isLate){
                e.start = addDays(new Date(e.start), 7)
                if (e.end) {
                    e.end = addDays(new Date(e.end), 7)
                }
            }
            else {
                e.start = reduceDays(new Date(e.start), 7)
                if (e.end) {
                    e.end = reduceDays(new Date(e.end), 7)
                }
            }

            return e
        })
        localStorage.setItem(partTime ? 'part-eventData' : 'full-eventData', JSON.stringify(newEvents))
    }

    function handleDrop({event}){
        let cohortNumber = window.location.pathname.split('/')[1]
        if (!cohortNumber) {
            return;
        }
        let chooseData = getFinalEventData()
        let eventsClone = JSON.parse(JSON.stringify(chooseData))
        let newEvents = eventsClone.map((e) => {
            if (e.id == event.id) {
                e.start = addDays(event.start, 1)
            }
            return e
        })
        localStorage.setItem(partTime ? 'part-eventData' : 'full-eventData', JSON.stringify(newEvents))
        setShowReset(true)
    }

    const eventData = getFinalEventData()

    let cohortNumber = window.location.pathname.split('/')[1]
    const startDate = cohorts[cohortNumber]?.date || defaultDate
    return (
        <>
        {
                isDemo && (
                    <div className="previewHeader">
                        This is a DEMO preview of the calendar. To view your personal calendar visit <a href="https://uxuiopen.com/dashboard" target="_blank">{` your dashboard `}</a> once you start to learn.
                    </div>
                )
            }
        <div className="demo-app">
            {renderSidebar()}
            <div className="demo-app-main">
                <FullCalendar
                    plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                    headerToolbar={{
                        left: "prev,next today",
                        center: "title",
                        right: "dayGridMonth",
                    }}
                    // validRange={{
                    //     start: startDate,
                    //     end: isLate ? addDays(startDate, 173) : addDays(startDate, 166)
                    //   }}
                    initialView="dayGridMonth"
                    editable={true}
                    selectable={true}
                    selectMirror={true}
                    dayMaxEvents={true}
                    events={eventData}
                    select={handleDateSelect}
                    eventContent={renderEventContent} 
                    eventClick={handleEventClick}
                    eventDrop={handleDrop}
                />
            </div>
        </div>
        </>
    );
}

export default DemoApp;
