import {
  cohorts,
  defaultDate
} from './cohortDates'


let cohortNumber = window.location.pathname.split('/')[1]
let todayStr = cohorts[cohortNumber]?.date || defaultDate

export function setTodayStr(cohortNum){
  todayStr = cohorts[cohortNum]?.date || defaultDate
}


let eventGuid = 0

function createEventId() {
  return String(eventGuid++)
}

export function formatDate(d) {
  return d.toISOString().replace(/T.*$/, '')
}

export function addDays(date, number = 0) {
  let cloneDate = structuredClone(date)
  let someDate = new Date(cloneDate.setDate(cloneDate.getDate() + number));
  return formatDate(someDate)
}

export function reduceDays(date, number = 0) {
  let cloneDate = structuredClone(date)
  let someDate = new Date(cloneDate.setDate(cloneDate.getDate() - number));
  return formatDate(someDate)
}

export const INITIAL_EVENTS_PART = [{
    id: createEventId(),
    title: '01 - Orientation',
    start: addDays(todayStr),
    allDay: true,
    classNames: 'eventClass gold',
    extendedProps: {
      desc: 'Get started with the UX/UI Open curriculum and set yourself up with the best support system and network to ace your learning',
      url: `https://www.uxuiopen.com/docs/intro`,
      activities: [{
        name: 'Introduce yourself in Slack',
        url: `https://www.uxuiopen.com/docs/orientation/03#activity-`
      }],
      assignments: [{
        name: '#uxui-assignment01',
        url: `https://www.uxuiopen.com/docs/orientation/04#assignment-1-`
      }],
    }
  },
  {
    id: createEventId(),
    title: '2.1 - Way of the designer',
    start: addDays(todayStr, 1),
    allDay: true,
    classNames: 'eventClass gold',
    extendedProps: {
      isCheckpoint: true,
      desc: 'Understand the course structure and overview. Read the checkpoint and complete the assignment at the bottom',
      url: `https://www.uxuiopen.com/docs/ux_fundamentals/the_way_of_the_designer`,
      activities: [],
      assignments: [{
        name: '#uxui-assignment02',
        url: `https://www.uxuiopen.com/docs/ux_fundamentals/the_way_of_the_designer#assignment-2-`
      }],
    }
  },
  {
    id: createEventId(),
    title: '2.2 - Your Career',
    start: addDays(todayStr, 1),
    allDay: true,
    classNames: 'eventClass normal',
    extendedProps: {
      isCheckpoint: true,
      desc: 'You will be able to identify the essential user experience design components. You will also be able to compare and contrast different career tracks available within the industry.',
      url: `https://www.uxuiopen.com/docs/ux_fundamentals/your_career`,
      activities: [],
      assignments: [],
    }
  },
  {
    id: createEventId(),
    title: '2.3 - Design Thinking',
    start: addDays(todayStr, 2),
    allDay: true,
    classNames: 'eventClass gold',
    extendedProps: {
      isCheckpoint: true,
      desc: 'You will be able to define and explain the design process and advocate for the importance of empathy in design. You will demonstrate an initial ability to empathize with a user by producing two empathy maps',
      url: `https://www.uxuiopen.com/docs/ux_fundamentals/design_thinking`,
      activities: [],
      assignments: [{
        name: '#uxui-assignment03',
        url: `https://www.uxuiopen.com/docs/ux_fundamentals/design_thinking#assignment-3-`
      }],
    },
  },
  {
    id: createEventId(),
    title: '2.4 - Understanding Users',
    start: addDays(todayStr, 2),
    allDay: true,
    classNames: 'eventClass normal',
    extendedProps: {
      isCheckpoint: true,
      desc: 'By the end of this checkpoint, you will be able to advocate for the value of user research. You will also be able to explain the history of human-centered design and describe how it applies to user research.',
      url: `https://www.uxuiopen.com/docs/ux_fundamentals/understanding_users`,
      activities: [],
      assignments: [],
    },
  },
  {
    id: createEventId(),
    title: '2.5 - The Visuals',
    start: addDays(todayStr, 3),
    allDay: true,
    classNames: 'eventClass normal',
    extendedProps: {
      isCheckpoint: true,
      desc: 'In this checkpoint, you will be able to compare and contrast UI, UX, and frontend development. You will then evaluate the essential components of these varying career paths.',
      url: `https://www.uxuiopen.com/docs/ux_fundamentals/the_visuals`,
      activities: [],
      assignments: [],
    },
  },
  {
    id: createEventId(),
    title: '2.6 - Designing For People',
    start: addDays(todayStr, 3),
    allDay: true,
    classNames: 'eventClass normal',
    extendedProps: {
      isCheckpoint: true,
      desc: 'By the end of this checkpoint, you will be able to discuss the different components of accessibility. You will be able to explain why they are important and how they contribute to better usability.',
      url: `https://www.uxuiopen.com/docs/ux_fundamentals/for_people`,
      activities: [],
      assignments: [],
    },
  },
  {
    id: createEventId(),
    title: '2.7 - Design Process',
    start: addDays(todayStr, 4),
    allDay: true,
    classNames: `eventClass graded`,
    extendedProps: {
      isCheckpoint: true,
      isGraded: true,
      desc: 'By the end of this checkpoint, you will be able to describe all the phases of the double-diamond design process and implement them on a narrowly scoped problem.',
      url: `https://www.uxuiopen.com/docs/ux_fundamentals/design_process`,
      activities: [],
      assignments: [{
        name: '#uxui-assignment04',
        url: `https://www.uxuiopen.com/docs/ux_fundamentals/design_process#assignment-4-`
      }],
    },
  },
  {
    id: createEventId(),
    title: '2.8 - Design Foundations',
    start: addDays(todayStr, 4),
    allDay: true,
    classNames: 'eventClass normal',
    extendedProps: {
      isCheckpoint: true,
      desc: 'By the end of this checkpoint, you will be able to self-evaluate your understanding of design thinking, the design process, UX design and research, UI and visual design, accessibility, and usability.',
      url: `https://www.uxuiopen.com/docs/ux_fundamentals/design_foundations`,
      activities: [],
      assignments: [],
    },
  },
  {
    id: createEventId(),
    title: 'Mentor Call 1',
    start: addDays(todayStr, 4),
    allDay: true,
    classNames: `eventClass mentor`,
    extendedProps: {
      isCheckpoint: true,
      desc: 'Visit ADP list and schedule your mentor call. Ensure you let the mentor know in advance the doubts you have. Jot down a few questions you have in mind that you would want to ask them. Share the uxuiopen link if necessary for them to know what doubts you have',
      url: `https://uxuiopen.com/docs/ux_fundamentals/design_process#mentor-call-1-`,
      activities: [],
      assignments: [],
    },
  },
  {
    id: createEventId(),
    title: '3.1 - User Centered Research',
    start: addDays(todayStr, 7),
    allDay: true,
    classNames: `eventClass normal`,
    extendedProps: {
      isCheckpoint: true,
      desc: `By the end of this checkpoint, you will have an understanding of the user-centered design (UCD) process as you evaluate user research aspects of the UX field..
        `,
      url: `https://www.uxuiopen.com/docs/user_centered_design/user_centered_research`,
      activities: [],
      assignments: [],
    },
  },
  {
    id: createEventId(),
    title: '3.2 - Using Research',
    start: addDays(todayStr, 7),
    allDay: true,
    classNames: `eventClass normal`,
    extendedProps: {
      isCheckpoint: true,
      desc: `By the end of this checkpoint, you will be able to articulate why it is important to always consider the experience of the people who will interact with or be impacted by the use of a product or service that you are designing. You will also be able to demonstrate familiarity with methods and tools that UX/UI designers use to better understand users' points of view.`,
      url: `https://www.uxuiopen.com/docs/user_centered_design/using_research`,
      activities: [],
      assignments: [],
    },
  },
  {
    id: createEventId(),
    title: '3.3 - Research Methods',
    start: addDays(todayStr, 8),
    allDay: true,
    classNames: `eventClass normal`,
    extendedProps: {
      isCheckpoint: true,
      desc: `By the end of this checkpoint, you will be able to identify common user experience research methods. You will also become familiar with the methods and tools that we use to better understand the users' points of view.`,
      url: `https://www.uxuiopen.com/docs/user_centered_design/research_methods`,
      guides: [`<li>If you're finished with the checkpoint, as a group, ask a fw people on slack on <b>#uxui-cohort-1</b> & evaluate the <a href="https://newyork.craigslist.org/" rel="nofollow">Craigslist website</a> and determine ways you can improve it using the <a href="https://www.nngroup.com/articles/ten-usability-heuristics/" rel="nofollow">10 Usability Heuristics</a> as a guide.</li>`],
      activities: [],
      assignments: [],
    },
  },
  {
    id: createEventId(),
    title: '3.4 - Insight Translation',
    start: addDays(todayStr, 8),
    allDay: true,
    classNames: `eventClass normal`,
    extendedProps: {
      isCheckpoint: true,
      desc: `By the end of this checkpoint, you will be able to find and create foundational documents that will help you organize your research results. You will also be able to prioritize and communicate the most important decision-influencing insights that you gleaned from this research.`,
      url: `https://www.uxuiopen.com/docs/user_centered_design/insight`,
      activities: [],
      assignments: [],
    },
  },
  {
    id: createEventId(),
    title: '3.5 - Ongoing Evaluation',
    start: addDays(todayStr, 9),
    allDay: true,
    classNames: `eventClass normal`,
    extendedProps: {
      isCheckpoint: true,
      desc: `By the end of this checkpoint, you will be able to recognize and list multiple methods that can be used to evaluate the performance of your design.`,
      url: `https://www.uxuiopen.com/docs/user_centered_design/evaluation`,
      activities: [],
      assignments: [],
    },
  },
  {
    id: createEventId(),
    title: '3.6 Assignment',
    start: addDays(todayStr, 9),
    allDay: true,
    classNames: `eventClass gold`,
    extendedProps: {
      isCheckpoint: true,
      desc: `Analytics, goals, and KPIs`,
      url: `https://www.uxuiopen.com/docs/user_centered_design/evaluation`,
      activities: [],
      assignments: [{
        name: 'Self Graded',
        url: `https://www.uxuiopen.com/docs/user_centered_design/assignment`
      }],
    },
  },
  {
    id: createEventId(),
    title: '4.1 Principles',
    start: addDays(todayStr, 10),
    allDay: true,
    classNames: `eventClass normal`,
    extendedProps: {
      isCheckpoint: true,
      desc: `By the end of this checkpoint, you will be able to identify the principles and elements of visual design and describe how they are used to create positive user experiences.`,
      url: `https://www.uxuiopen.com/docs/visual_design/principles`,
      guides: [],
      activities: [],
      assignments: [],
    },
  },
  {
    id: createEventId(),
    title: '4.2 Accessible Design',
    start: addDays(todayStr, 10),
    allDay: true,
    classNames: `eventClass normal`,
    extendedProps: {
      isCheckpoint: true,
      desc: `By the end of this checkpoint, you will be able to describe how the look and feel of a product or service can affect its usability and accessibility.`,
      url: `https://www.uxuiopen.com/docs/visual_design/accessible`,
      guides: [],
      activities: [{
        name: 'WAVE acivity',
        url: `https://www.uxuiopen.com/docs/visual_design/accessible#activity-`
      }],
      assignments: [],
    },
  },
  {
    id: createEventId(),
    title: '4.3 Designing Experiences',
    start: addDays(todayStr, 11),
    allDay: true,
    classNames: `eventClass normal`,
    extendedProps: {
      isCheckpoint: true,
      desc: `By the end of this checkpoint, you will be able to describe methods for using empathy as a designer. You will also be able to use the five senses theory to evaluate designs.`,
      url: `https://www.uxuiopen.com/docs/visual_design/memorable_ex`,
      guides: [],
      activities: [{
        name: 'Senses theory graph',
        url: `https://www.uxuiopen.com/docs/visual_design/memorable_ex#activity-`
      }],
      assignments: [],
    },
  },
  {
    id: createEventId(),
    title: '4.4 Mood Boards',
    start: addDays(todayStr, 11),
    allDay: true,
    classNames: `eventClass normal`,
    extendedProps: {
      isCheckpoint: true,
      desc: `By the end of this checkpoint, you will be able to identify basic design deliverables and practice creating deliverables with industry-standard tools.`,
      url: `https://www.uxuiopen.com/docs/visual_design/mood_boards`,
      guides: [],
      activities: [],
      assignments: [],
    },
  },
  {
    id: createEventId(),
    title: '4.5 Visual Design Foundations',
    start: addDays(todayStr, 14),
    allDay: true,
    classNames: `eventClass normal`,
    extendedProps: {
      isCheckpoint: true,
      desc: `By the end of this checkpoint, you will be able to use industry-standard tools to create a simple style tile. You will also demonstrate your understanding of design principles and deliverables.`,
      url: `https://www.uxuiopen.com/docs/visual_design/visual_design_fd`,
      guides: [],
      activities: [],
      assignments: [],
    },
  },
  {
    id: createEventId(),
    title: '4.6 End Of Fundamentals',
    start: addDays(todayStr, 14),
    allDay: true,
    classNames: `eventClass gold`,
    extendedProps: {
      isCheckpoint: true,
      desc: `By the end of this checkpoint, you will be able to self-evaluate your understanding of the design process, UX design and research, UI and visual design, and usability. You will begin preparations for the next skills and concepts that you'll learn, and get ready to start building some real-world products.`,
      url: `https://www.uxuiopen.com/docs/visual_design/graduating`,
      guides: [],
      activities: [],
      assignments: [{
        name: 'Self Evaluated',
        url: `https://www.uxuiopen.com/docs/visual_design/graduating#assignment-`
      }],
    },
  },
  {
    id: createEventId(),
    title: 'Mentor Call 2',
    start: addDays(todayStr, 14),
    allDay: true,
    classNames: `eventClass mentor`,
    extendedProps: {
      isCheckpoint: true,
      desc: 'Visit ADP list and schedule your mentor call. Ensure you let the mentor know in advance the doubts you have. Jot down a few questions you have in mind that you would want to ask them. Share the uxuiopen link if necessary for them to know what doubts you have',
      url: `https://www.uxuiopen.com/docs/visual_design/graduating/#mentor-call-2-`,
      activities: [],
      assignments: [],
    },
  },
  {
    id: createEventId(),
    title: 'CATCH UP',
    start: addDays(todayStr, 15),
    end: addDays(todayStr, 17),
    // allDay: true,
    classNames: `eventClass green`,
    extendedProps: {
      isCheckpoint: true,
      desc: `Take rest. Catch up if you've fallen behind schedule. The coming weeks are going to be intense`,
      url: 'https://www.youtube.com/watch?v=dQw4w9WgXcQ',
      activities: [],
      assignments: [],
    },
  },
  {
    id: createEventId(),
    title: '5.1 Dicovery Workshop',
    start: addDays(todayStr, 17),
    allDay: true,
    classNames: `eventClass gold`,
    extendedProps: {
      isCheckpoint: true,
      desc: `By the end of this checkpoint, you will be able to identify information to be collected from the UX discovery client or team ideations, scope definitions, and goal-setting workshops. You will also be able to describe the goals for the UX intensive course project, a bus app.`,
      url: `https://www.uxuiopen.com/docs/uxi_discover/workshop/`,
      guides: [],
      activities: [],
      assignments: [{
        name: 'Bus App Project',
        url: `https://www.uxuiopen.com/docs/uxi_discover/workshop/#bus-app-project-overview-`
      }],
    },
  },
  {
    id: createEventId(),
    title: '5.2 Research Methods',
    start: addDays(todayStr, 17),
    allDay: true,
    classNames: `eventClass normal`,
    extendedProps: {
      isCheckpoint: true,
      desc: `By the end of this checkpoint, you will be able to identify common user experience research methods and structure a simple research plan.`,
      url: `https://www.uxuiopen.com/docs/uxi_discover/research_methods/`,
      guides: [],
      activities: [],
      assignments: [],
    },
  },
  {
    id: createEventId(),
    title: '5.3 Competitive Analysis',
    start: addDays(todayStr, 18),
    allDay: true,
    classNames: `eventClass normal`,
    extendedProps: {
      isCheckpoint: true,
      desc: `By the end of this checkpoint, you will be able to perform a competitive analysis and explain why this activity is a vital part of the design process.`,
      url: `https://www.uxuiopen.com/docs/uxi_discover/competitive_analysis/`,
      guides: [],
      activities: [],
      assignments: [],
    },
  },
  {
    id: createEventId(),
    title: '5.4 User Surveys',
    start: addDays(todayStr, 18),
    allDay: true,
    classNames: `eventClass normal`,
    extendedProps: {
      isCheckpoint: true,
      desc: `By the end of this checkpoint, you will be able to create surveys that efficiently collect data from potential users.`,
      url: `https://www.uxuiopen.com/docs/uxi_discover/user_surveys/`,
      guides: [],
      activities: [],
      assignments: [],
    },
  },
  {
    id: createEventId(),
    title: '5.5 Conducting Interviews',
    start: addDays(todayStr, 21),
    allDay: true,
    classNames: `eventClass gold`,
    extendedProps: {
      isCheckpoint: true,
      desc: `By the end of this checkpoint, you will be able to prepare for and execute research interviews to better understand the needs of your target audience`,
      url: `https://www.uxuiopen.com/docs/uxi_discover/interviws/`,
      guides: [],
      activities: [],
      assignments: [{
        name: 'Resarch Interviews',
        url: `https://www.uxuiopen.com/docs/uxi_discover/interviws/#research-interviews`
      }],
    },
  },
  {
    id: createEventId(),
    title: '5.6 User Personas',
    start: addDays(todayStr, 22),
    allDay: true,
    classNames: `eventClass normal`,
    extendedProps: {
      isCheckpoint: true,
      desc: `By the end of this checkpoint, you will be able to use results from user surveys and interviews to generate personas.`,
      url: `https://www.uxuiopen.com/docs/uxi_discover/personas/`,
      guides: [],
      activities: [],
      assignments: [],
    },
  },
  {
    id: createEventId(),
    title: '5.7 Experience Mapping',
    start: addDays(todayStr, 22),
    allDay: true,
    classNames: `eventClass normal`,
    extendedProps: {
      isCheckpoint: true,
      desc: `By the end of this checkpoint, you will be able to use results from user surveys and interviews to generate personas.`,
      url: `https://www.uxuiopen.com/docs/uxi_discover/idea_mapping/`,
      guides: [],
      activities: [],
      assignments: [],
    },
  },
  {
    id: createEventId(),
    title: '5.8 Sharing Insights',
    start: addDays(todayStr, 22),
    allDay: true,
    classNames: `eventClass normal`,
    extendedProps: {
      isCheckpoint: true,
      desc: `By the end of this checkpoint, you will be able to extract and prioritize what you learn from your research. You'll also be able to turn your research outputs into an attractive and informative presentation.`,
      url: `https://www.uxuiopen.com/docs/uxi_discover/sharing_insights/`,
      guides: [],
      activities: [],
      assignments: [],
    },
  },
  {
    id: createEventId(),
    title: '6.1 Define Phase',
    start: addDays(todayStr, 23),
    allDay: true,
    classNames: `eventClass gold`,
    extendedProps: {
      isCheckpoint: true,
      desc: `By the end of the checkpoint, you will be able to explain the purpose of the define phase and use the research information, requirements, and other specifications to create user stories.`,
      url: `https://www.uxuiopen.com/docs/uxi_define/intro/`,
      guides: [],
      activities: [],
      assignments: [{
        name: 'Assignment 6: User stories',
        url: `https://www.uxuiopen.com/docs/uxi_define/intro/#assignment-6-`
      }],
    },
  },
  {
    id: createEventId(),
    title: '6.2 User Flows',
    start: addDays(todayStr, 23),
    allDay: true,
    classNames: `eventClass graded`,
    extendedProps: {
      isCheckpoint: true,
      isGraded: true,
      desc: `By the end of the checkpoint, you will be able to use the research information, requirements, and other specifications to create a user flow for a project.`,
      url: `https://www.uxuiopen.com/docs/uxi_define/user_flows/`,
      guides: [],
      activities: [],
      assignments: [{
        name: 'Assignment 7: Drafting user flows',
        url: `https://www.uxuiopen.com/docs/uxi_define/user_flows/#assignment-7-`
      }],
    },
  },
  {
    id: createEventId(),
    title: 'Mentor Call 3',
    start: addDays(todayStr, 23),
    allDay: true,
    classNames: `eventClass mentor`,
    extendedProps: {
      isCheckpoint: true,
      desc: 'Visit ADP list and schedule your mentor call. Ensure you let the mentor know in advance the doubts you have. Jot down a few questions you have in mind that you would want to ask them. Share the uxuiopen link if necessary for them to know what doubts you have',
      url: `https://www.uxuiopen.com/docs/uxi_define/user_flows/#mentor-call-3-`,
      activities: [],
      assignments: [],
    },
  },
  {
    id: createEventId(),
    title: '6.3 Content Strategy',
    start: addDays(todayStr, 24),
    allDay: true,
    classNames: `eventClass gold`,
    extendedProps: {
      isCheckpoint: true,
      desc: `By the end of the checkpoint, you will be able to use the research you've conducted, requirements, and other specifications to define site maps.`,
      url: `https://www.uxuiopen.com/docs/uxi_define/content_strategy/`,
      guides: [],
      activities: [],
      assignments: [{
        name: 'Assignment 8: Site map',
        url: `https://www.uxuiopen.com/docs/uxi_define/content_strategy/#assignment-8-`
      }],
    },
  },
  {
    id: createEventId(),
    title: '6.4 Wireframes',
    start: addDays(todayStr, 25),
    allDay: true,
    classNames: `eventClass gold`,
    extendedProps: {
      isCheckpoint: true,
      desc: `By the end of the checkpoint, you will be able to use the research information, requirements, and other specifications to create wireframes for a project.`,
      url: `https://www.uxuiopen.com/docs/uxi_define/wireframes/`,
      guides: [],
      activities: [],
      assignments: [{
        name: 'Assignment 9: Wireframes',
        url: `https://www.uxuiopen.com/docs/uxi_define/wireframes/#assignment-9-`
      }],
    },
  },
  {
    id: createEventId(),
    title: '6.5 Clickable Prototype',
    start: addDays(todayStr, 28),
    allDay: true,
    classNames: `eventClass normal`,
    extendedProps: {
      isCheckpoint: true,
      desc: `By the end of the checkpoint, you will be able to use the research information, requirements, and other specifications to define a clickable prototype.`,
      url: `https://www.uxuiopen.com/docs/uxi_define/clickable_prototype/`,
      guides: [],
      activities: [],
      assignments: [],
    },
  },
  {
    id: createEventId(),
    title: '6.6 Usability Test',
    start: addDays(todayStr, 28),
    allDay: true,
    classNames: `eventClass normal`,
    extendedProps: {
      isCheckpoint: true,
      desc: `By the end of the checkpoint, you will be able to refine your prototype so that it incorporates stakeholder and user feedback.`,
      url: `https://www.uxuiopen.com/docs/uxi_define/usability/`,
      guides: [],
      activities: [],
      assignments: [],
    },
  },
  {
    id: createEventId(),
    title: '7.1 Develop Intro',
    start: addDays(todayStr, 29),
    allDay: true,
    classNames: `eventClass normal`,
    extendedProps: {
      isCheckpoint: true,
      desc: `By the end of this checkpoint, you will be able to describe the overall work to be done in the develop phase. You will also be able to identify strong designs for inspiration.`,
      url: `https://www.uxuiopen.com/docs/uxi_develop/intro/`,
      guides: [],
      activities: [{
        name: 'Design inspiration',
        url: `https://www.uxuiopen.com/docs/uxi_develop/intro/#activity-`
      }],
      assignments: [],
    },
  },
  {
    id: createEventId(),
    title: '7.2 Design Systems',
    start: addDays(todayStr, 29),
    allDay: true,
    classNames: `eventClass normal`,
    extendedProps: {
      isCheckpoint: true,
      desc: `By the end of the checkpoint, you will be able to argue for the importance of a design system and describe some popular design systems.`,
      url: `https://www.uxuiopen.com/docs/uxi_develop/design_systems/`,
      guides: [],
      activities: [],
      assignments: [],
    },
  },
  {
    id: createEventId(),
    title: '7.3 Best Practices',
    start: addDays(todayStr, 30),
    allDay: true,
    classNames: `eventClass normal`,
    extendedProps: {
      isCheckpoint: true,
      desc: `By the end of the checkpoint, you will be able to define mental models and describe best practices for visual design.`,
      url: `https://www.uxuiopen.com/docs/uxi_develop/best_practices/`,
      guides: [],
      activities: [],
      assignments: [],
    },
  },
  {
    id: createEventId(),
    title: '7.4 Design Requirements',
    start: addDays(todayStr, 30),
    allDay: true,
    classNames: `eventClass normal`,
    extendedProps: {
      isCheckpoint: true,
      desc: `By the end of the checkpoint, you will be able to evaluate viewport requirements and apply responsive design methods to your own design process.`,
      url: `https://www.uxuiopen.com/docs/uxi_develop/design_requirements/`,
      guides: [],
      activities: [],
      assignments: [],
    },
  },
  {
    id: createEventId(),
    title: '7.5 Developing Layout',
    start: addDays(todayStr, 31),
    allDay: true,
    classNames: `eventClass normal`,
    extendedProps: {
      isCheckpoint: true,
      desc: `By the end of the checkpoint, you will be able to apply the concepts of hierarchy, space, and grid systems to improve the functionality of layout designs.`,
      url: `https://www.uxuiopen.com/docs/uxi_develop/developing_layout/`,
      guides: [],
      activities: [],
      assignments: [],
    },
  },
  {
    id: createEventId(),
    title: '7.6 Branding',
    start: addDays(todayStr, 31),
    allDay: true,
    classNames: `eventClass gold`,
    extendedProps: {
      isCheckpoint: true,
      desc: `By the end of this checkpoint, you will apply your research, requirements, and other specifications to define a brand and create a mood board`,
      url: `https://www.uxuiopen.com/docs/uxi_develop/branding/`,
      guides: [],
      activities: [],
      assignments: [{
        name: 'Assignment 10: Mood Board',
        url: `https://www.uxuiopen.com/docs/uxi_develop/branding/#assignment-10-`
      }],
    },
  },
  {
    id: createEventId(),
    title: '7.7 Typography',
    start: addDays(todayStr, 32),
    allDay: true,
    classNames: `eventClass normal`,
    extendedProps: {
      isCheckpoint: true,
      desc: `By the end of the checkpoint, you will apply your research, requirements, and other specifications to define a brand and create a typographical guide.`,
      url: `https://www.uxuiopen.com/docs/uxi_develop/typography/`,
      guides: [],
      activities: [],
      assignments: [],
    },
  },
  {
    id: createEventId(),
    title: '7.8 Color Palette',
    start: addDays(todayStr, 32),
    allDay: true,
    classNames: `eventClass normal`,
    extendedProps: {
      isCheckpoint: true,
      desc: `By the end of the checkpoint, you will be able to apply color theory to choosing an appropriate color palette for a project.`,
      url: `https://www.uxuiopen.com/docs/uxi_develop/color/`,
      guides: [],
      activities: [{
        name: 'Bus App color palette',
        url: `https://www.uxuiopen.com/docs/uxi_develop/color/#activity-`
      }],
      assignments: [],
    },
  },
  {
    id: createEventId(),
    title: '7.9 Logo',
    start: addDays(todayStr, 35),
    allDay: true,
    classNames: `eventClass gold`,
    extendedProps: {
      isCheckpoint: true,
      desc: `By the end of the checkpoint, you will be able to sketch and create a digital logo for a project and generate a name for an app`,
      url: `https://www.uxuiopen.com/docs/uxi_develop/logo/`,
      guides: [],
      activities: [],
      assignments: [{
        name: 'Assignment 11: Logo',
        url: `https://www.uxuiopen.com/docs/uxi_develop/logo/#assignment-11-`
      }],
    },
  },
  {
    id: createEventId(),
    title: '7.10 Hifi Mockup',
    start: addDays(todayStr, 35),
    allDay: true,
    classNames: `eventClass gold`,
    extendedProps: {
      isCheckpoint: true,
      desc: `By the end of the checkpoint, you will be able to create high-fidelity deliverables.`,
      url: `https://www.uxuiopen.com/docs/uxi_develop/hifi_mockups/`,
      guides: [],
      activities: [],
      assignments: [{
        name: 'Assignment 12',
        url: `https://www.uxuiopen.com/docs/uxi_develop/hifi_mockups/#creating-a-high-fidelity-prototype`
      }],
    },
  },
  {
    id: createEventId(),
    title: 'Mentor Call 4',
    start: addDays(todayStr, 35),
    allDay: true,
    classNames: `eventClass mentor`,
    extendedProps: {
      isCheckpoint: true,
      desc: 'Visit ADP list and schedule your mentor call. Ensure you let the mentor know in advance the doubts you have. Jot down a few questions you have in mind that you would want to ask them. Share the uxuiopen link if necessary for them to know what doubts you have',
      url: `https://www.uxuiopen.com/docs/uxi_define/user_flows/#mentor-call-3-`,
      activities: [],
      assignments: [],
    },
  },
  {
    id: createEventId(),
    title: '8.1 Deliver',
    start: addDays(todayStr, 36),
    allDay: true,
    classNames: `eventClass normal`,
    extendedProps: {
      isCheckpoint: true,
      desc: `By the end of this checkpoint, you will be able to describe the overall work to be done in the deliver phase.`,
      url: `https://www.uxuiopen.com/docs/uxi_deliver/intro/`,
      guides: [],
      activities: [{
        name: 'List your deliverables',
        url: `https://www.uxuiopen.com/docs/uxi_deliver/intro/#activity-`
      }],
      assignments: [],
    },
  },
  {
    id: createEventId(),
    title: '8.2 Prototype',
    start: addDays(todayStr, 36),
    allDay: true,
    classNames: `eventClass normal`,
    extendedProps: {
      isCheckpoint: true,
      desc: `By the end of the checkpoint, you will be able to refine your clickable prototype for your bus project.`,
      url: `https://www.uxuiopen.com/docs/uxi_deliver/prototype/`,
      guides: [],
      activities: [],
      assignments: [],
    },
  },
  {
    id: createEventId(),
    title: '8.3 Usability Tests',
    start: addDays(todayStr, 37),
    allDay: true,
    classNames: `eventClass normal`,
    extendedProps: {
      isCheckpoint: true,
      desc: `By the end of the checkpoint, you will know how to test your clickable prototype for usability.`,
      url: `https://www.uxuiopen.com/docs/uxi_deliver/usability_tests/`,
      guides: [],
      activities: [],
      assignments: [],
    },
  },
  {
    id: createEventId(),
    title: '8.4 Usability Reports',
    start: addDays(todayStr, 37),
    allDay: true,
    classNames: `eventClass normal`,
    extendedProps: {
      isCheckpoint: true,
      desc: `By the end of the checkpoint, you will know how to create usability reports and communicate findings to stakeholders.`,
      url: `https://www.uxuiopen.com/docs/uxi_deliver/usability_reports/`,
      guides: [],
      activities: [],
      assignments: [],
    },
  },
  {
    id: createEventId(),
    title: '8.5 Delivering Assets',
    start: addDays(todayStr, 38),
    allDay: true,
    classNames: `eventClass normal`,
    extendedProps: {
      isCheckpoint: true,
      desc: `By the end of the checkpoint, you will discover how to deliver design elements like logos, icons, and fonts. In addition, this checkpoint provides tips for asset organization.`,
      url: `https://www.uxuiopen.com/docs/uxi_deliver/delivering_assets/`,
      guides: [],
      activities: [],
      assignments: [],
    },
  },
  {
    id: createEventId(),
    title: '8.6 Case Study',
    start: addDays(todayStr, 38),
    allDay: true,
    classNames: `eventClass normal`,
    extendedProps: {
      isCheckpoint: true,
      desc: `By the end of the checkpoint, you will be able to draft a case study for your portfolio, demonstrating your design process and thinking.`,
      url: `https://www.uxuiopen.com/docs/uxi_deliver/case_study/`,
      guides: [],
      activities: [],
      assignments: [],
    },
  },
  {
    id: createEventId(),
    title: '8.7 Presentation',
    start: addDays(todayStr, 39),
    allDay: true,
    classNames: `eventClass normal`,
    extendedProps: {
      isCheckpoint: true,
      desc: `By the end of this checkpoint, you will be able to present your work persuasively and confidently.`,
      url: `https://www.uxuiopen.com/docs/uxi_deliver/presentation/`,
      guides: [],
      activities: [],
      assignments: [],
    },
  },
  {
    id: createEventId(),
    title: 'CATCH UP',
    start: addDays(todayStr, 42),
    end: addDays(todayStr, 45),
    // allDay: true,
    classNames: `eventClass green`,
    extendedProps: {
      isCheckpoint: true,
      desc: `Take rest. Catch up if you've fallen behind schedule.`,
      url: 'https://www.youtube.com/watch?v=dQw4w9WgXcQ',
      activities: [],
      assignments: [],
    },
  },
  {
    id: createEventId(),
    title: '9.1 UX teams',
    start: addDays(todayStr, 45),
    allDay: true,
    classNames: `eventClass normal`,
    extendedProps: {
      isCheckpoint: true,
      desc: `By the end of this checkpoint, you will be able to compare and contrast different roles on a real-world UX team and analyze how they function within the greater business organization.`,
      url: `https://www.uxuiopen.com/docs/defining_a_product/01/`,
      guides: [],
      activities: [],
      assignments: [],
    },
  },
  {
    id: createEventId(),
    title: '9.2 Requirements',
    start: addDays(todayStr, 45),
    allDay: true,
    classNames: `eventClass normal`,
    extendedProps: {
      isCheckpoint: true,
      desc: `By the end of this checkpoint, you will be able to measure team success through defining UX requirements and establishing parameters, recognizing the value of a design sprint, and applying this knowledge to your own app project.`,
      url: `https://www.uxuiopen.com/docs/defining_a_product/02/`,
      guides: [],
      activities: [],
      assignments: [],
    },
  },
  {
    id: createEventId(),
    title: '9.3 Interviewing',
    start: addDays(todayStr, 46),
    allDay: true,
    classNames: `eventClass normal`,
    extendedProps: {
      isCheckpoint: true,
      desc: `By the end of this checkpoint, you will be able to conduct efficient user research and define a target audience for your users.`,
      url: `https://www.uxuiopen.com/docs/defining_a_product/03/`,
      guides: [],
      activities: [],
      assignments: [],
    },
  },
  {
    id: createEventId(),
    title: '9.4 Pick A Target',
    start: addDays(todayStr, 46),
    allDay: true,
    classNames: `eventClass gold`,
    extendedProps: {
      isCheckpoint: true,
      desc: `By the end of this checkpoint, you will be able to compare and contrast different methods of product development and how it affects the day-to-day workflow on a UX team. You will pick a target to focus on during your design sprint.`,
      url: `https://www.uxuiopen.com/docs/defining_a_product/04/`,
      guides: [],
      activities: [],
      assignments: [{
        name: 'Self Graded: Sticky Notes',
        url: `https://www.uxuiopen.com/docs/defining_a_product/04/#assignment-`
      }],
    },
  },
  {
    id: createEventId(),
    title: '9.5 Archetypes',
    start: addDays(todayStr, 49),
    allDay: true,
    classNames: `eventClass normal`,
    extendedProps: {
      isCheckpoint: true,
      desc: `By the end of this checkpoint, you will be able to apply the concepts of archetypes to the team design process, visualizing the user journey with empathy maps and user journey maps.`,
      url: `https://www.uxuiopen.com/docs/defining_a_product/05/`,
      guides: [],
      activities: [{
        name: 'Map the UX',
        url: `https://www.uxuiopen.com/docs/defining_a_product/05/#activity-`
      }],
      assignments: [],
    },
  },
  {
    id: createEventId(),
    title: '9.6 Flowchart',
    start: addDays(todayStr, 49),
    allDay: true,
    classNames: `eventClass normal`,
    extendedProps: {
      isCheckpoint: true,
      desc: `By the end of this checkpoint, you will be able to analyze the value of a good interview and apply these values to your design sprint. You will be able to create a flowchart for your app project and write a script for interviewing your client.`,
      url: `https://www.uxuiopen.com/docs/defining_a_product/06/`,
      guides: [],
      activities: [{
        name: 'Flowchart Sketch',
        url: `https://www.uxuiopen.com/docs/defining_a_product/06/#activity-`
      }],
      assignments: [],
    },
  },
  {
    id: createEventId(),
    title: '9.7 Designer As DJ',
    start: addDays(todayStr, 50),
    allDay: true,
    classNames: `eventClass normal`,
    extendedProps: {
      isCheckpoint: true,
      desc: `By the end of this checkpoint, you will be able to review and evaluate the concept of remix by looking to existing design solutions as inspiration. As part of your design sprint, you will complete lightning demos for the app project.`,
      url: `https://www.uxuiopen.com/docs/defining_a_product/07/`,
      guides: [],
      activities: [{
        name: 'Lightning Demos',
        url: `https://www.uxuiopen.com/docs/defining_a_product/07/#activity-`
      }],
      assignments: [],
    },
  },
  {
    id: createEventId(),
    title: '9.8 Sketch',
    start: addDays(todayStr, 50),
    allDay: true,
    classNames: `eventClass gold`,
    extendedProps: {
      isCheckpoint: true,
      desc: `By the end of this checkpoint, you will be able to complete the four-step sketch process for a portion of your product for your client.`,
      url: `https://www.uxuiopen.com/docs/defining_a_product/08/`,
      guides: [],
      activities: [],
      assignments: [{
        name: 'Assignment 13: Four-step sketch',
        url: `https://www.uxuiopen.com/docs/defining_a_product/08/#assignment-13-`
      }],
    },
  },
  {
    id: createEventId(),
    title: '9.9 Extracting Insights',
    start: addDays(todayStr, 51),
    allDay: true,
    classNames: `eventClass normal`,
    extendedProps: {
      isCheckpoint: true,
      desc: `By the end of this checkpoint, you will be able to extract insights by applying the aesthetic-usability effect or attractiveness bias design principle to your design critique. This helps you make sense of the data you've gathered and the sketches you've made to prioritize an actionable strategy.`,
      url: `https://www.uxuiopen.com/docs/defining_a_product/09/`,
      guides: [],
      activities: [],
      assignments: [],
    },
  },
  {
    id: createEventId(),
    title: '9.10 Recruiting Users',
    start: addDays(todayStr, 51),
    allDay: true,
    classNames: `eventClass normal`,
    extendedProps: {
      isCheckpoint: true,
      desc: `By the end of this checkpoint, you will be able to evaluate the Pareto principle (or 80/20 rule) as it pertains to decision-making, recruit users for user testing, and craft wireframes for your app project.`,
      url: `https://www.uxuiopen.com/docs/defining_a_product/10/`,
      guides: [],
      activities: [{
        name: 'Research tasks',
        url: `https://www.uxuiopen.com/docs/defining_a_product/10/#activity-`
      }],
      assignments: [],
    },
  },
  {
    id: createEventId(),
    title: 'Mentor Call 5',
    start: addDays(todayStr, 50),
    allDay: true,
    classNames: `eventClass mentor`,
    extendedProps: {
      isCheckpoint: true,
      desc: 'Visit ADP list and schedule your mentor call. Ensure you let the mentor know in advance the doubts you have. Jot down a few questions you have in mind that you would want to ask them. Share the uxuiopen link if necessary for them to know what doubts you have',
      url: `https://www.uxuiopen.com/docs/defining_a_product/08/#mentor-call-5-`,
      activities: [],
      assignments: [],
    },
  },
  {
    id: createEventId(),
    title: '10.1 Accessibility',
    start: addDays(todayStr, 52),
    allDay: true,
    classNames: `eventClass normal`,
    extendedProps: {
      isCheckpoint: true,
      desc: `By the end of this checkpoint, you will be able to apply the concept of accessibility to the design process. You'll be able to visualize the user journey and contextualize prior sketches through storyboarding. You'll be able to piece together the sketches and user data that you've gathered.`,
      url: `https://www.uxuiopen.com/docs/prototyping/01/`,
      guides: [],
      activities: [],
      assignments: [],
    },
  },
  {
    id: createEventId(),
    title: '10.2 Oraganizing Content',
    start: addDays(todayStr, 52),
    allDay: true,
    classNames: `eventClass normal`,
    extendedProps: {
      isCheckpoint: true,
      desc: `By the end of this checkpoint, you will be able to step back and reflect on your work so far. You will evaluate the content as it aligns with the materials you've generated during this sprint..`,
      url: `https://www.uxuiopen.com/docs/prototyping/02/`,
      guides: [],
      activities: [],
      assignments: [],
    },
  },
  {
    id: createEventId(),
    title: '10.3 Paper Prototyping',
    start: addDays(todayStr, 53),
    allDay: true,
    classNames: `eventClass normal`,
    extendedProps: {
      isCheckpoint: true,
      desc: `By the end of this checkpoint, you will compare and contrast paper prototyping as a design tool. You'll be able to create a usable, testable paper prototype model. You'll use this paper prototype model as a sketch for your low fidelity prototype that you will initiate in a future checkpoint.`,
      url: `https://www.uxuiopen.com/docs/prototyping/03/`,
      guides: [],
      activities: [],
      assignments: [],
    },
  },
  {
    id: createEventId(),
    title: '10.4 Tools',
    start: addDays(todayStr, 53),
    allDay: true,
    classNames: `eventClass normal`,
    extendedProps: {
      isCheckpoint: true,
      desc: `By the end of this checkpoint, you will be able to create a plan for prototyping. You will evaluate and pick the right tools that you feel comfortable using to present the wireframes and storyboards to stakeholders.`,
      url: `https://www.uxuiopen.com/docs/prototyping/04/`,
      guides: [],
      activities: [],
      assignments: [],
    },
  },
  {
    id: createEventId(),
    title: '10.5 Trial Run',
    start: addDays(todayStr, 56),
    allDay: true,
    classNames: `eventClass normal`,
    extendedProps: {
      isCheckpoint: true,
      desc: `By the end of this checkpoint, you will be able to analyze the requirements for quantitative and qualitative user testing and apply them to the design process. This will help you be ready for the next user-testing week of your design sprint.`,
      url: `https://www.uxuiopen.com/docs/prototyping/05/`,
      guides: [],
      activities: [],
      assignments: [],
    },
  },
  {
    id: createEventId(),
    title: '10.6 User Testing',
    start: addDays(todayStr, 56),
    allDay: true,
    classNames: `eventClass normal`,
    extendedProps: {
      isCheckpoint: true,
      desc: `By the end of this checkpoint, you will be able to analyze good interview questions and the value of creating a testing guide. This will help you be ready for the user-testing phase of your design sprint.`,
      url: `https://www.uxuiopen.com/docs/prototyping/06/`,
      guides: [],
      activities: [],
      assignments: [],
    },
  },
  {
    id: createEventId(),
    title: '10.7 Prototype',
    start: addDays(todayStr, 57),
    allDay: true,
    classNames: `eventClass normal`,
    extendedProps: {
      isCheckpoint: true,
      desc: `By the end of the checkpoint, you will begin to piece together the sketches and user data you've gathered to create a real, client-facing prototype.`,
      url: `https://www.uxuiopen.com/docs/prototyping/07/`,
      guides: [],
      activities: [],
      assignments: [],
    },
  },
  {
    id: createEventId(),
    title: '10.8 More Prototyping',
    start: addDays(todayStr, 57),
    allDay: true,
    classNames: `eventClass normal`,
    extendedProps: {
      isCheckpoint: true,
      desc: `By the end of the checkpoint, you will be able to finish piecing together your sketches, wireframes, paper prototypes, and the user data you've gathered to complete your real and testable client-facing prototype.`,
      url: `https://www.uxuiopen.com/docs/prototyping/08/`,
      guides: [],
      activities: [],
      assignments: [],
    },
  },
  {
    id: createEventId(),
    title: '10.9 Internal Test',
    start: addDays(todayStr, 58),
    allDay: true,
    classNames: `eventClass normal`,
    extendedProps: {
      isCheckpoint: true,
      desc: `By the end of this checkpoint, you will be able to edit your low fidelity prototype that you've built and conduct a trial run.`,
      url: `https://www.uxuiopen.com/docs/prototyping/09/`,
      guides: [],
      activities: [],
      assignments: [],
    },
  },
  {
    id: createEventId(),
    title: '10.10 Stitching It',
    start: addDays(todayStr, 58),
    allDay: true,
    classNames: `eventClass normal`,
    extendedProps: {
      isCheckpoint: true,
      desc: `By the end of this checkpoint, you will be able to analyze and apply the concept of heuristic evaluation to the team design process. You'll evaluate and improve your prototypes so you can be ready for the final user-testing day of your design sprint.`,
      url: `https://www.uxuiopen.com/docs/prototyping/10/`,
      guides: [],
      activities: [],
      assignments: [],
    },
  },
  {
    id: createEventId(),
    title: '11.1 to 11.4',
    start: addDays(todayStr, 59),
    allDay: true,
    classNames: `eventClass normal`,
    extendedProps: {
      isCheckpoint: true,
      desc: `Finish checkpoints 11.1 to 11.4`,
      url: `https://www.uxuiopen.com/docs/presenting/01/`,
      guides: [],
      activities: [],
      assignments: [],
    },
  },
  {
    id: createEventId(),
    title: '11.5 Stakeholders',
    start: addDays(todayStr, 60),
    allDay: true,
    classNames: `eventClass normal`,
    extendedProps: {
      isCheckpoint: true,
      desc: `By the end of this checkpoint, you will be able to present your app project to your stakeholder for feedback.`,
      url: `https://www.uxuiopen.com/docs/presenting/05/`,
      guides: [],
      activities: [],
      assignments: [],
    },
  },
  {
    id: createEventId(),
    title: '11.6 Planning For Dev',
    start: addDays(todayStr, 60),
    allDay: true,
    classNames: `eventClass normal`,
    extendedProps: {
      isCheckpoint: true,
      desc: `By the end of this checkpoint, you will begin planning for your the client presentation with an eye toward final product development, launch, and release`,
      url: `https://www.uxuiopen.com/docs/presenting/06/`,
      guides: [],
      activities: [],
      assignments: [],
    },
  },
  {
    id: createEventId(),
    title: '11.7 Presentation',
    start: addDays(todayStr, 63),
    allDay: true,
    classNames: `eventClass graded`,
    extendedProps: {
      isCheckpoint: true,
      isGraded: true,
      desc: `By the end of this checkpoint, you will be able to amend your presentation by incorporating stakeholder feedback and explaining your rationale behind the changes made.`,
      url: `https://www.uxuiopen.com/docs/presenting/07/`,
      guides: [],
      activities: [],
      assignments: [{
        name: 'Assignment 14: Client presentation',
        url: `https://www.uxuiopen.com/docs/presenting/07/#assignment-14-`
      }],
    },
  },
  {
    id: createEventId(),
    title: '11.8 External Presentation',
    start: addDays(todayStr, 63),
    allDay: true,
    classNames: `eventClass normal`,
    extendedProps: {
      isCheckpoint: true,
      desc: `By the end of this checkpoint, you will be able to create an external client presentation that you can pitch to your client in a confident, professional, and persuasive manner`,
      url: `https://www.uxuiopen.com/docs/presenting/08/`,
      guides: [],
      activities: [],
      assignments: [],
    },
  },
  {
    id: createEventId(),
    title: '11.9 Tour Of Trends',
    start: addDays(todayStr, 64),
    allDay: true,
    classNames: `eventClass normal`,
    extendedProps: {
      isCheckpoint: true,
      desc: `By the end of the checkpoint, you will be able to compare and contrast different roles on a real-world UX team. You'll also be able to analyze the current job market, including trends that affect how you search for jobs and grow your career`,
      url: `https://www.uxuiopen.com/docs/presenting/09/`,
      guides: [],
      activities: [],
      assignments: [],
    },
  },
  {
    id: createEventId(),
    title: '11.10 Real-World Team',
    start: addDays(todayStr, 64),
    allDay: true,
    classNames: `eventClass gold`,
    extendedProps: {
      isCheckpoint: true,
      desc: `By the end of this checkpoint, you can compare and contrast different ways to build an authentic network of UX professional relationships. You will build your portfolio and prepare for job interviews.`,
      url: `https://www.uxuiopen.com/docs/presenting/10/`,
      guides: [],
      activities: [],
      assignments: [{
        name: 'Interview Designers',
        url: `https://www.uxuiopen.com/docs/presenting/10/#assignment--`
      }],
    },
  },
  {
    id: createEventId(),
    title: 'Mentor Call 6',
    start: addDays(todayStr, 65),
    allDay: true,
    classNames: `eventClass mentor`,
    extendedProps: {
      isCheckpoint: true,
      desc: 'Visit ADP list and schedule your mentor call. Ensure you let the mentor know in advance the doubts you have. Jot down a few questions you have in mind that you would want to ask them. Share the uxuiopen link if necessary for them to know what doubts you have',
      url: `https://www.uxuiopen.com/docs/presenting/10/#mentor-call-6-`,
      activities: [],
      assignments: [],
    },
  },
  {
    id: createEventId(),
    title: '12.1 Your Work',
    start: addDays(todayStr, 65),
    allDay: true,
    classNames: `eventClass portfolio`,
    extendedProps: {
      isCheckpoint: true,
      desc: `By the end of this checkpoint, you will be able to identify the important elements of a portfolio.`,
      url: `https://www.uxuiopen.com/docs/portfolio1/01/`,
      guides: [],
      activities: [],
      assignments: [],
    },
  },
  {
    id: createEventId(),
    title: '12.2 Creating A Portfolio',
    start: addDays(todayStr, 66),
    allDay: true,
    classNames: `eventClass portfolio`,
    extendedProps: {
      isCheckpoint: true,
      desc: `By the end of this checkpoint, you will begin to implement the foundation of your portfolio.`,
      url: `https://www.uxuiopen.com/docs/portfolio1/02/`,
      guides: [],
      activities: [],
      assignments: [],
    },
  },
  {
    id: createEventId(),
    title: '12.3 Case Studies',
    start: addDays(todayStr, 66),
    allDay: true,
    classNames: `eventClass portfolio`,
    extendedProps: {
      isCheckpoint: true,
      desc: `By the end of this checkpoint, you will be able to identify and curate the appropriate assets for a case study.`,
      url: `https://www.uxuiopen.com/docs/portfolio1/03/`,
      guides: [],
      activities: [{
        name: 'Content strategy',
        url: `https://www.uxuiopen.com/docs/portfolio1/03/#activity-`
      }],
      assignments: [],
    },
  },
  {
    id: createEventId(),
    title: '12.4 Sketching',
    start: addDays(todayStr, 67),
    allDay: true,
    classNames: `eventClass gold`,
    extendedProps: {
      isCheckpoint: true,
      desc: `By the end of this checkpoint, you will be able to sketch layout solutions for your portfol`,
      url: `https://www.uxuiopen.com/docs/portfolio1/04/`,
      guides: [],
      activities: [],
      assignments: [{
        name: 'Assignment 15: Portfolio Sktches',
        url: `https://www.uxuiopen.com/docs/portfolio1/04/#assignment-15-`
      }],
    },
  },
  {
    id: createEventId(),
    title: '12.5 Home Page',
    start: addDays(todayStr, 67),
    allDay: true,
    classNames: `eventClass portfolio`,
    extendedProps: {
      isCheckpoint: true,
      desc: `By the end of this checkpoint, you will be able to create a wireframe of your portfolio's home page and identify structural components to carry forward in the rest of your portfolio.`,
      url: `https://www.uxuiopen.com/docs/portfolio1/05/`,
      guides: [],
      activities: [{
        name: 'Portfolio Wireframe: Home',
        url: `https://www.uxuiopen.com/docs/portfolio1/05/#activity-`
      }],
      assignments: [],
    },
  },
  {
    id: createEventId(),
    title: '12.6 About Page',
    start: addDays(todayStr, 70),
    allDay: true,
    classNames: `eventClass portfolio`,
    extendedProps: {
      isCheckpoint: true,
      desc: `By the end of this checkpoint, you will be able to create a wireframe for your case study that can serve as a template for other pages.`,
      url: `https://www.uxuiopen.com/docs/portfolio1/06/`,
      guides: [],
      activities: [{
        name: 'Portfolio Wireframe: About',
        url: `https://www.uxuiopen.com/docs/portfolio1/06/#activity-`
      }],
      assignments: [],
    },
  },
  {
    id: createEventId(),
    title: '12.7 Biography Writing',
    start: addDays(todayStr, 70),
    allDay: true,
    classNames: `eventClass portfolio`,
    extendedProps: {
      isCheckpoint: true,
      desc: `By the end of this checkpoint, you will be able to identify critical moments that resulted in you taking the path to becoming a designer in order to write a compelling biography`,
      url: `https://www.uxuiopen.com/docs/portfolio1/07/`,
      guides: [],
      activities: [],
      assignments: [],
    },
  },
  {
    id: createEventId(),
    title: '12.8 Publishing',
    start: addDays(todayStr, 71),
    allDay: true,
    classNames: `eventClass gold`,
    extendedProps: {
      isCheckpoint: true,
      desc: `By the end of this checkpoint, you will be able to publish the foundation of your portfolio online.`,
      url: `https://www.uxuiopen.com/docs/portfolio1/09/`,
      guides: [],
      activities: [],
      assignments: [{
        name: 'Assignment 16: Branding',
        url: `https://www.uxuiopen.com/docs/portfolio1/09/#assignment-16-`
      }],
    },
  },
  {
    id: createEventId(),
    title: 'CATCH UP',
    start: addDays(todayStr, 72),
    end: addDays(todayStr, 75),
    // allDay: true,
    classNames: `eventClass green`,
    extendedProps: {
      isCheckpoint: true,
      desc: `Take rest. Catch up if you've fallen behind schedule. The coming weeks are going to be intense`,
      url: 'https://www.youtube.com/watch?v=dQw4w9WgXcQ',
      activities: [],
      assignments: [],
    },
  },
  {
    id: createEventId(),
    title: '13 User Research I',
    start: addDays(todayStr, 77),
    allDay: true,
    classNames: `eventClass normal`,
    extendedProps: {
      isCheckpoint: true,
      desc: `By the end of this checkpoint, you will be able to identify typical job roles and responsibilities of a user experience researcher and choose an appropriate website to study for your project, which will be a comparative and heuristic analysis.`,
      url: `https://www.uxuiopen.com/docs/category/13---user-research-i/`,
      guides: [],
      activities: [],
      assignments: [],
    },
  },
  {
    id: createEventId(),
    title: '14 Visual Design I',
    start: addDays(todayStr, 78),
    allDay: true,
    classNames: `eventClass gold`,
    extendedProps: {
      isCheckpoint: true,
      desc: `By the end of the checkpoint, you will review how the visual design of a product's user interface fits within the design process, evaluate some new methods of content personalization and adaptive UI, and apply these techniques to the user interface you are being asked to design for this module.`,
      url: `https://www.uxuiopen.com/docs/specialization_visual1/01/`,
      guides: [],
      activities: [],
      assignments: [{
          name: 'Self Graded: Plan the UI',
          url: `https://www.uxuiopen.com/docs/specialization_visual1/01/#assignment-`
        },
        {
          name: 'Self Graded: Create the UI',
          url: `https://www.uxuiopen.com/docs/specialization_visual1/02/#assignment-`
        }
      ],
    },
  },

  {
    id: createEventId(),
    title: 'Mentor Call 7',
    start: addDays(todayStr, 78),
    allDay: true,
    classNames: `eventClass mentor`,
    extendedProps: {
      isCheckpoint: true,
      desc: 'Visit ADP list and schedule your mentor call. Ensure you let the mentor know in advance the doubts you have. Jot down a few questions you have in mind that you would want to ask them. Share the uxuiopen link if necessary for them to know what doubts you have',
      url: `https://www.uxuiopen.com/docs/presenting/10/#mentor-call-6-`,
      activities: [],
      assignments: [],
    },
  },
  {
    id: createEventId(),
    title: '14.3 Viewport Requirements',
    start: addDays(todayStr, 79),
    allDay: true,
    classNames: `eventClass normal`,
    extendedProps: {
      isCheckpoint: true,
      desc: `By the end of the checkpoint, you will be able to evaluate viewport requirements, understand the power of emotion and touchpoints in visual design, and apply these methods to your own visual design process for your client app UI.`,
      url: `https://www.uxuiopen.com/docs/specialization_visual1/03/`,
      guides: [],
      activities: [],
      assignments: [],
    },
  },
  {
    id: createEventId(),
    title: '14.4 Designing Screens',
    start: addDays(todayStr, 79),
    allDay: true,
    classNames: `eventClass normal`,
    extendedProps: {
      isCheckpoint: true,
      desc: `By the end of the checkpoint, you will be able to continue designing the screens for your client app project and have deliverables to present to your client in the next checkpoint.`,
      url: `https://www.uxuiopen.com/docs/specialization_visual1/04/`,
      guides: [],
      activities: [],
      assignments: [],
    },
  },
  {
    id: createEventId(),
    title: '14.5 Testing Screens',
    start: addDays(todayStr, 80),
    allDay: true,
    classNames: `eventClass normal`,
    extendedProps: {
      isCheckpoint: true,
      desc: `By the end of the checkpoint, you will be able to test your high-fidelity mockups and incorporate user feedback into your design.`,
      url: `https://www.uxuiopen.com/docs/specialization_visual1/05/`,
      guides: [],
      activities: [{
        name: 'Testing using Maze',
        url: `https://www.uxuiopen.com/docs/specialization_visual1/05/#activity-testing-screens-`
      }],
      assignments: [],
    },
  },
  {
    id: createEventId(),
    title: '15 FrontEnd (Optional)',
    start: addDays(todayStr, 81),
    allDay: true,
    classNames: `eventClass optional`,
    extendedProps: {
      isCheckpoint: true,
      desc: `By the end of the checkpoint, you will have an understanding of the foundations of frontend development.`,
      url: `https://www.uxuiopen.com/docs/specialization_front1/01/`,
      guides: [],
      activities: [],
      assignments: [],
    },
  },
  {
    id: createEventId(),
    title: '15 FrontEnd (Optional)',
    start: addDays(todayStr, 84),
    allDay: true,
    classNames: `eventClass optional`,
    extendedProps: {
      isCheckpoint: true,
      desc: `By the end of the checkpoint, you will have an understanding of the foundations of frontend development.`,
      url: `https://www.uxuiopen.com/docs/specialization_front1/01/`,
      guides: [],
      activities: [],
      assignments: [],
    },
  },
  {
    id: createEventId(),
    title: '16.1 User Research II',
    start: addDays(todayStr, 85),
    allDay: true,
    classNames: `eventClass gold`,
    extendedProps: {
      isCheckpoint: true,
      desc: `By the end of this checkpoint, you will be able to describe when and why to use a card sort as a research method. You will also create a site map of an existing website as the first step in your research.`,
      url: `https://www.uxuiopen.com/docs/specialization_user2/01/`,
      guides: [],
      activities: [],
      assignments: [],
    },
  },
  {
    id: createEventId(),
    title: '16.2 - 16.4',
    start: addDays(todayStr, 86),
    end: addDays(todayStr, 88),
    allDay: true,
    classNames: `eventClass normal`,
    extendedProps: {
      isCheckpoint: true,
      desc: `Complete going over lessons 16.2 to 16.4`,
      url: `https://www.uxuiopen.com/docs/specialization_user2/02/`,
      guides: [],
      activities: [{
          name: 'Planning Card Sort',
          url: `https://www.uxuiopen.com/docs/specialization_user2/02/#activity-`
        },
        {
          name: 'Running Card Sort',
          url: `https://www.uxuiopen.com/docs/specialization_user2/03/#activity-`
        },
        {
          name: 'Analysing Results',
          url: `https://www.uxuiopen.com/docs/specialization_user2/04/#activity-`
        }
      ],
      assignments: [],
    },
  },
  {
    id: createEventId(),
    title: '16.5 Illustrating',
    start: addDays(todayStr, 88),
    allDay: true,
    classNames: `eventClass gold`,
    extendedProps: {
      isCheckpoint: true,
      desc: `By the end of this checkpoint, you will be able to describe the card-sort research process and justify design decisions in a case study.`,
      url: `https://www.uxuiopen.com/docs/specialization_user2/05/`,
      guides: [],
      activities: [],
      assignments: [{
        name: 'Self Graded: Case study outline',
        url: `https://www.uxuiopen.com/docs/specialization_user2/05/#assignment-`
      }],
    },
  },
  {
    id: createEventId(),
    title: '17.1 Activities App',
    start: addDays(todayStr, 91),
    allDay: true,
    classNames: `eventClass normal`,
    extendedProps: {
      isCheckpoint: true,
      desc: `By the end of this checkpoint, you will be able to describe when and why to use a card sort as a research method. You will also create a site map of an existing website as the first step in your research.`,
      url: `https://www.uxuiopen.com/docs/specialization_visual2/01/`,
      guides: [],
      activities: [],
      assignments: [],
    },
  },
  {
    id: createEventId(),
    title: '17.2 Design Components',
    start: addDays(todayStr, 92),
    allDay: true,
    classNames: `eventClass gold`,
    extendedProps: {
      isCheckpoint: true,
      desc: `By the end of the checkpoint, you will be able to design components that fit the branding of LocalEyez. You will revisit the principles and elements of visual design and hone your skills as a T-shaped designer.`,
      url: `https://www.uxuiopen.com/docs/specialization_visual2/02/`,
      guides: [],
      activities: [],
      assignments: [{
        name: 'Self Graded',
        url: `https://www.uxuiopen.com/docs/specialization_visual2/02/#assignment-`
      }],
    },
  },
  
  {
    id: createEventId(),
    title: 'Mentor Call 8',
    start: addDays(todayStr, 92),
    allDay: true,
    classNames: `eventClass mentor`,
    extendedProps: {
      isCheckpoint: true,
      desc: 'Visit ADP list and schedule your mentor call. Ensure you let the mentor know in advance the doubts you have. Jot down a few questions you have in mind that you would want to ask them. Share the uxuiopen link if necessary for them to know what doubts you have',
      url: `https://www.uxuiopen.com/docs/presenting/10/#mentor-call-6-`,
      activities: [],
      assignments: [],
    },
  },
  {
    id: createEventId(),
    title: '17.3 & 17.4',
    start: addDays(todayStr, 93),
    end: addDays(todayStr, 95),
    classNames: `eventClass normal`,
    extendedProps: {
      isCheckpoint: true,
      desc: `By the end of the checkpoint, you will be able to design interactions for LocalEyez. You will also explore in-app animations using splash screens, loading screens, and more. Although you won't be animating, you will be provided with a list of additional resources for if you choose to learn animation`,
      url: `https://www.uxuiopen.com/docs/specialization_visual2/03/`,
      guides: [],
      activities: [],
      assignments: [],
    },
  },
  {
    id: createEventId(),
    title: '17.5 Review',
    start: addDays(todayStr, 95),
    allDay: true,
    classNames: `eventClass gold`,
    extendedProps: {
      isCheckpoint: true,
      desc: `By the end of the checkpoint, you will have completed all the steps to producing great UI for LocalEyez. You will then showcase your knowledge by writing a case study for your work.`,
      url: `https://www.uxuiopen.com/docs/specialization_visual2/05/`,
      guides: [],
      activities: [],
      assignments: [{
        name: 'Assignment 17: Case Study',
        url: `https://www.uxuiopen.com/docs/specialization_visual2/05/#assignment-17-`
      }],
    },
  },
  {
    id: createEventId(),
    title: '18 Front End II (Optional)',
    start: addDays(todayStr, 98),
    end: addDays(todayStr, 103),
    // allDay: true,
    classNames: `eventClass optional`,
    extendedProps: {
      isCheckpoint: true,
      desc: `This section introduces you to the world of programming. It's not a mandatory thing to learn to code to be a product designer but it can set you apart from the rest. Feel free to skip this section and return whenever you feel comfortable`,
      url: `https://www.uxuiopen.com/docs/category/18---front-end-design-ii/`,
      guides: [],
      activities: [],
      assignments: [],
    },
  },
  {
    id: createEventId(),
    title: '18 Front End II (Optional)',
    start: addDays(todayStr, 105),
    end: addDays(todayStr, 108),
    // allDay: true,
    classNames: `eventClass optional`,
    extendedProps: {
      isCheckpoint: true,
      desc: `This section introduces you to the world of programming. It's not a mandatory thing to learn to code to be a product designer but it can set you apart from the rest. Feel free to skip this section and return whenever you feel comfortable`,
      url: `https://www.uxuiopen.com/docs/category/18---front-end-design-ii/`,
      guides: [],
      activities: [],
      assignments: [],
    },
  },
  {
    id: createEventId(),
    title: 'CATCH UP',
    start: addDays(todayStr, 108),
    end: addDays(todayStr, 110),
    // allDay: true,
    classNames: `eventClass green`,
    extendedProps: {
      isCheckpoint: true,
      desc: `Take rest. Catch up if you've fallen behind schedule.`,
      url: 'https://www.youtube.com/watch?v=dQw4w9WgXcQ',
      activities: [],
      assignments: [],
    },
  },
  {
    id: createEventId(),
    title: '19 User Research III',
    start: addDays(todayStr, 112),
    allDay: true,
    classNames: `eventClass gold`,
    extendedProps: {
      isCheckpoint: true,
      desc: `By the end of this checkpoint, you will be able to create a brief research plan for a usability testing project.`,
      url: `https://www.uxuiopen.com/docs/category/19---user-research-iii/`,
      guides: [],
      activities: [],
      assignments: [{
        name: 'Self Graded',
        url: `https://www.uxuiopen.com/docs/specialization_user3/05/#assignment-`
      }],
    },
  },
  {
    id: createEventId(),
    title: '20.1 Machine Learning App',
    start: addDays(todayStr, 113),
    allDay: true,
    classNames: `eventClass gold`,
    extendedProps: {
      isCheckpoint: true,
      desc: `By the end of the checkpoint, you will be able to evaluate machine-learning technology and apply it to the user interface you are being asked to design for this module.`,
      url: `https://www.uxuiopen.com/docs/specialization_visual3/01/`,
      guides: [],
      activities: [],
      assignments: [{
        name: 'Assignment 18',
        url: `https://www.uxuiopen.com/docs/specialization_visual3/01/#assignment-18-`
      }],
    },
  },
  {
    id: createEventId(),
    title: '20.2 Design Principles',
    start: addDays(todayStr, 114),
    allDay: true,
    classNames: `eventClass gold`,
    extendedProps: {
      isCheckpoint: true,
      desc: `By the end of the checkpoint, you will be able to evaluate design principles and apply them to the user interface you are being asked to design for this module.`,
      url: `https://www.uxuiopen.com/docs/specialization_visual3/02/`,
      guides: [],
      activities: [],
      assignments: [{
        name: 'Assignment 18 continue',
        url: `https://www.uxuiopen.com/docs/specialization_visual3/02/#assignment-18-continue-`
      }],
    },
  },
  {
    id: createEventId(),
    title: '20.4 Refining UI',
    start: addDays(todayStr, 115),
    allDay: true,
    classNames: `eventClass normal`,
    extendedProps: {
      isCheckpoint: true,
      desc: `By the end of the checkpoint, you will be able to take feedback and successfully integrate it into an existing design`,
      url: `https://www.uxuiopen.com/docs/specialization_visual3/04/`,
      guides: [],
      activities: [{
        name: 'Feedback changes',
        url: `https://www.uxuiopen.com/docs/specialization_visual3/04/#activity-`
      }],
      assignments: [],
    },
  },
  {
    id: createEventId(),
    title: '20.5 Wrap Up',
    start: addDays(todayStr, 115),
    allDay: true,
    classNames: `eventClass gold`,
    extendedProps: {
      isCheckpoint: true,
      desc: `By the end of the checkpoint, you will be able to showcase your knowledge by writing a case study for your work.`,
      url: `https://www.uxuiopen.com/docs/specialization_visual3/04/`,
      guides: [],
      activities: [],
      assignments: [{
        name: 'Wrap up',
        url: `https://www.uxuiopen.com/docs/specialization_visual3/05/#assignment-20-`
      }],
    },
  },
  {
    id: createEventId(),
    title: 'Mentor Call 9',
    start: addDays(todayStr, 116),
    allDay: true,
    classNames: `eventClass mentor`,
    extendedProps: {
      isCheckpoint: true,
      desc: 'Visit ADP list and schedule your mentor call. Ensure you let the mentor know in advance the doubts you have. Jot down a few questions you have in mind that you would want to ask them. Share the uxuiopen link if necessary for them to know what doubts you have',
      url: `https://www.uxuiopen.com/docs/presenting/10/#mentor-call-6-`,
      activities: [],
      assignments: [],
    },
  },
  {
    id: createEventId(),
    title: '18 Front End III (Optional)',
    start: addDays(todayStr, 116),
    allDay: true,
    classNames: `eventClass optional`,
    extendedProps: {
      isCheckpoint: true,
      desc: `By the end of this checkpoint, you will start to understand how JavaScript is used within websites.`,
      url: `https://www.uxuiopen.com/docs/category/21---front-end-design-iii/`,
      guides: [],
      activities: [],
      assignments: [],
    },
  },
  {
    id: createEventId(),
    title: '21 Front End III (Optional)',
    start: addDays(todayStr, 119),
    end: addDays(todayStr, 124),
    // allDay: true,
    classNames: `eventClass optional`,
    extendedProps: {
      isCheckpoint: true,
      desc: `By the end of this checkpoint, you will start to understand how JavaScript is used within websites.`,
      url: `https://www.uxuiopen.com/docs/category/21---front-end-design-iii/`,
      guides: [],
      activities: [],
      assignments: [],
    },
  },
  {
    id: createEventId(),
    title: '21 Front End III (Optional)',
    start: addDays(todayStr, 126),
    end: addDays(todayStr, 129),
    // allDay: true,
    classNames: `eventClass optional`,
    extendedProps: {
      isCheckpoint: true,
      desc: `By the end of this checkpoint, you will start to understand how JavaScript is used within websites.`,
      url: `https://www.uxuiopen.com/docs/category/21---front-end-design-iii/`,
      guides: [],
      activities: [],
      assignments: [],
    },
  },
  {
    id: createEventId(),
    title: 'CATCH UP',
    start: addDays(todayStr, 129),
    end: addDays(todayStr, 131),
    // allDay: true,
    classNames: `eventClass green`,
    extendedProps: {
      isCheckpoint: true,
      desc: `Take rest. Catch up if you've fallen behind schedule.`,
      url: 'https://www.youtube.com/watch?v=dQw4w9WgXcQ',
      activities: [],
      assignments: [],
    },
  },
  {
    id: createEventId(),
    title: '22 Capstone: Discover',
    start: addDays(todayStr, 133),
    end: addDays(todayStr, 138),
    // allDay: true,
    classNames: `eventClass normal`,
    extendedProps: {
      isCheckpoint: true,
      desc: `It's finally time to build your own project. Every project you build in the program is critical to your success as a designer. Whether you are starting a business, joining a company, or contracting your design skills, your ability to solve problems through design will become your most important qualification. Each finished project should showcase this ability.`,
      url: `https://www.uxuiopen.com/docs/22/`,
      guides: [],
      activities: [],
      assignments: [],
    },
  },
  {
    id: createEventId(),
    title: '23 Capstone: Develop',
    start: addDays(todayStr, 140),
    end: addDays(todayStr, 145),
    // allDay: true,
    classNames: `eventClass normal`,
    extendedProps: {
      isCheckpoint: true,
      desc: `It's finally time to build your own project. Every project you build in the program is critical to your success as a designer. Whether you are starting a business, joining a company, or contracting your design skills, your ability to solve problems through design will become your most important qualification. Each finished project should showcase this ability.`,
      url: `https://www.uxuiopen.com/docs/capstone_develop/01/`,
      guides: [],
      activities: [],
      assignments: [],
    },
  },
  {
    id: createEventId(),
    title: '24 Capstone: Deliver',
    start: addDays(todayStr, 147),
    end: addDays(todayStr, 152),
    // allDay: true,
    classNames: `eventClass normal`,
    extendedProps: {
      isCheckpoint: true,
      desc: `It's finally time to build your own project. Every project you build in the program is critical to your success as a designer. Whether you are starting a business, joining a company, or contracting your design skills, your ability to solve problems through design will become your most important qualification. Each finished project should showcase this ability.`,
      url: `https://www.uxuiopen.com/docs/capstone_develop/01/`,
      guides: [],
      activities: [],
      assignments: [],
    },
  },
  {
    id: createEventId(),
    title: 'Mentor Call 10',
    start: addDays(todayStr, 151),
    allDay: true,
    classNames: `eventClass mentor`,
    extendedProps: {
      isCheckpoint: true,
      desc: 'Visit ADP list and schedule your mentor call. Ensure you let the mentor know in advance the doubts you have. Jot down a few questions you have in mind that you would want to ask them. Share the uxuiopen link if necessary for them to know what doubts you have',
      url: `https://www.uxuiopen.com/docs/presenting/10/#mentor-call-6-`,
      activities: [],
      assignments: [],
    },
  },
  {
    id: createEventId(),
    title: '25 Capstone: Assessment',
    start: addDays(todayStr, 154),
    end: addDays(todayStr, 158),
    // allDay: true,
    classNames: `eventClass graded`,
    extendedProps: {
      isCheckpoint: true,
      desc: `Schedule your final bootcamp assessment`,
      url: `https://www.uxuiopen.com/docs/capstone_assessment/01/#assessment`,
      guides: [],
      activities: [],
      assignments: [],
    },
  },
  {
    id: createEventId(),
    title: '26.1 Refining Your Work',
    start: addDays(todayStr, 158),
    allDay: true,
    classNames: `eventClass normal`,
    extendedProps: {
      isCheckpoint: true,
      desc: `By the end of this checkpoint, you will add visual design and the remaining projects.`,
      url: `https://www.uxuiopen.com/docs/portfolio2/01/`,
      guides: [],
      activities: [],
      assignments: [],
    },
  },
  {
    id: createEventId(),
    title: '26.2 Handling NDAs',
    start: addDays(todayStr, 158),
    allDay: true,
    classNames: `eventClass normal`,
    extendedProps: {
      isCheckpoint: true,
      desc: `By the end of this checkpoint, you will be able to analyze and replicate how to present work that is protected by NDAs or other agreements.`,
      url: `https://www.uxuiopen.com/docs/portfolio2/02/`,
      guides: [],
      activities: [],
      assignments: [],
    },
  },
  {
    id: createEventId(),
    title: '26.3 User Testing',
    start: addDays(todayStr, 158),
    allDay: true,
    classNames: `eventClass normal`,
    extendedProps: {
      isCheckpoint: true,
      desc: `By the end of this checkpoint, you will user test your portfolio.`,
      url: `https://www.uxuiopen.com/docs/portfolio2/03/`,
      guides: [],
      activities: [],
      assignments: [],
    },
  },
  {
    id: createEventId(),
    title: 'Career Week',
    start: addDays(todayStr, 161),
    end: addDays(todayStr, 166),
    classNames: `eventClass normal`,
    extendedProps: {
      isCheckpoint: true,
      desc: `Now it's time for your job hunt. Breifly read the carrer checkpoints from Root Learn that'll help you land your new design job `,
      url: 'https://dashboard.rootlearn.com/c/day-1/',
      guides: [],
      activities: [],
      assignments: [],
    },
  },
  {
    id: createEventId(),
    title: 'GRADUATE 🥳',
    start: addDays(todayStr, 165),
    allDay: true,
    classNames: `eventClass graded`,
    extendedProps: {
      isCheckpoint: true,
      desc: `Give yourself a huge round of applause.`,
      url: `https://www.uxuiopen.com/docs/graduate/01/`,
      guides: [],
      activities: [],
      assignments: [],
    },
  },
]



export const INITIAL_EVENTS_FULL = [{
    id: createEventId(),
    title: '01 - Orientation',
    start: addDays(todayStr),
    allDay: true,
    classNames: 'eventClass gold',
    extendedProps: {
      desc: 'Get started with the UX/UI Open curriculum and set yourself up with the best support system and network to ace your learning',
      url: `https://www.uxuiopen.com/docs/intro`,
      activities: [{
        name: 'Introduce yourself in Slack',
        url: `https://www.uxuiopen.com/docs/orientation/03#activity-`
      }],
      assignments: [{
        name: '#uxui-assignment01',
        url: `https://www.uxuiopen.com/docs/orientation/04#assignment-1-`
      }],
    }
  },
  {
    id: createEventId(),
    title: '2.1 - Way of the designer',
    start: addDays(todayStr, 1),
    allDay: true,
    classNames: 'eventClass gold',
    extendedProps: {
      isCheckpoint: true,
      desc: 'Understand the course structure and overview. Read the checkpoint and complete the assignment at the bottom',
      url: `https://www.uxuiopen.com/docs/ux_fundamentals/the_way_of_the_designer`,
      activities: [],
      assignments: [{
        name: '#uxui-assignment02',
        url: `https://www.uxuiopen.com/docs/ux_fundamentals/the_way_of_the_designer#assignment-2-`
      }],
    }
  },
  {
    id: createEventId(),
    title: '2.2 - Your Career',
    start: addDays(todayStr, 1),
    allDay: true,
    classNames: 'eventClass normal',
    extendedProps: {
      isCheckpoint: true,
      desc: 'You will be able to identify the essential user experience design components. You will also be able to compare and contrast different career tracks available within the industry.',
      url: `https://www.uxuiopen.com/docs/ux_fundamentals/your_career`,
      activities: [],
      assignments: [],
    }
  },
  {
    id: createEventId(),
    title: '2.3 - Design Thinking',
    start: addDays(todayStr, 1),
    allDay: true,
    classNames: 'eventClass gold',
    extendedProps: {
      isCheckpoint: true,
      desc: 'You will be able to define and explain the design process and advocate for the importance of empathy in design. You will demonstrate an initial ability to empathize with a user by producing two empathy maps',
      url: `https://www.uxuiopen.com/docs/ux_fundamentals/design_thinking`,
      activities: [],
      assignments: [{
        name: '#uxui-assignment03',
        url: `https://www.uxuiopen.com/docs/ux_fundamentals/design_thinking#assignment-3-`
      }],
    },
  },
  {
    id: createEventId(),
    title: '2.4 - Understanding Users',
    start: addDays(todayStr, 1),
    allDay: true,
    classNames: 'eventClass normal',
    extendedProps: {
      isCheckpoint: true,
      desc: 'By the end of this checkpoint, you will be able to advocate for the value of user research. You will also be able to explain the history of human-centered design and describe how it applies to user research.',
      url: `https://www.uxuiopen.com/docs/ux_fundamentals/understanding_users`,
      activities: [],
      assignments: [],
    },
  },
  {
    id: createEventId(),
    title: '2.5 - The Visuals',
    start: addDays(todayStr, 2),
    allDay: true,
    classNames: 'eventClass normal',
    extendedProps: {
      isCheckpoint: true,
      desc: 'In this checkpoint, you will be able to compare and contrast UI, UX, and frontend development. You will then evaluate the essential components of these varying career paths.',
      url: `https://www.uxuiopen.com/docs/ux_fundamentals/the_visuals`,
      activities: [],
      assignments: [],
    },
  },
  {
    id: createEventId(),
    title: '2.6 - Designing For People',
    start: addDays(todayStr, 2),
    allDay: true,
    classNames: 'eventClass normal',
    extendedProps: {
      isCheckpoint: true,
      desc: 'By the end of this checkpoint, you will be able to discuss the different components of accessibility. You will be able to explain why they are important and how they contribute to better usability.',
      url: `https://www.uxuiopen.com/docs/ux_fundamentals/for_people`,
      activities: [],
      assignments: [],
    },
  },
  {
    id: createEventId(),
    title: '2.7 - Design Process',
    start: addDays(todayStr, 2),
    allDay: true,
    classNames: `eventClass graded`,
    extendedProps: {
      isCheckpoint: true,
      isGraded: true,
      desc: 'By the end of this checkpoint, you will be able to describe all the phases of the double-diamond design process and implement them on a narrowly scoped problem.',
      url: `https://www.uxuiopen.com/docs/ux_fundamentals/design_process`,
      activities: [],
      assignments: [{
        name: '#uxui-assignment04',
        url: `https://www.uxuiopen.com/docs/ux_fundamentals/design_process#assignment-4-`
      }],
    },
  },
  {
    id: createEventId(),
    title: '2.8 - Design Foundations',
    start: addDays(todayStr, 2),
    allDay: true,
    classNames: `eventClass normal`,
    extendedProps: {
      isCheckpoint: true,
      desc: `By the end of this checkpoint, you will be able to self-evaluate your understanding of design thinking, the design process, UX design and research, UI and visual design, accessibility, and usability.
      `,
      url: `https://www.uxuiopen.com/docs/ux_fundamentals/design_foundations`,
      activities: [],
      assignments: [],
    },
  },
  {
    id: createEventId(),
    title: 'Mentor Call 1',
    start: addDays(todayStr, 2),
    allDay: true,
    classNames: `eventClass mentor`,
    extendedProps: {
      isCheckpoint: true,
      desc: 'Visit ADP list and schedule your mentor call. Ensure you let the mentor know in advance the doubts you have. Jot down a few questions you have in mind that you would want to ask them. Share the uxuiopen link if necessary for them to know what doubts you have',
      url: `https://uxuiopen.com/docs/ux_fundamentals/design_process#mentor-call-1-`,
      activities: [],
      assignments: [],
    },
  },
  {
    id: createEventId(),
    title: '3.1 - User Centered Research',
    start: addDays(todayStr, 3),
    allDay: true,
    classNames: `eventClass normal`,
    extendedProps: {
      isCheckpoint: true,
      desc: `By the end of this checkpoint, you will have an understanding of the user-centered design (UCD) process as you evaluate user research aspects of the UX field..
      `,
      url: `https://www.uxuiopen.com/docs/user_centered_design/user_centered_research`,
      activities: [],
      assignments: [],
    },
  },
  {
    id: createEventId(),
    title: '3.2 - Using Research',
    start: addDays(todayStr, 3),
    allDay: true,
    classNames: `eventClass normal`,
    extendedProps: {
      isCheckpoint: true,
      desc: `By the end of this checkpoint, you will be able to articulate why it is important to always consider the experience of the people who will interact with or be impacted by the use of a product or service that you are designing. You will also be able to demonstrate familiarity with methods and tools that UX/UI designers use to better understand users' points of view.`,
      url: `https://www.uxuiopen.com/docs/user_centered_design/using_research`,
      activities: [],
      assignments: [],
    },
  },
  {
    id: createEventId(),
    title: '3.3 - Research Methods',
    start: addDays(todayStr, 3),
    allDay: true,
    classNames: `eventClass normal`,
    extendedProps: {
      isCheckpoint: true,
      desc: `By the end of this checkpoint, you will be able to identify common user experience research methods. You will also become familiar with the methods and tools that we use to better understand the users' points of view.`,
      url: `https://www.uxuiopen.com/docs/user_centered_design/research_methods`,
      guides: [`<li>If you're finished with the checkpoint, as a group, ask a fw people on slack on <b>#uxui-cohort-1</b> & evaluate the <a href="https://newyork.craigslist.org/" rel="nofollow">Craigslist website</a> and determine ways you can improve it using the <a href="https://www.nngroup.com/articles/ten-usability-heuristics/" rel="nofollow">10 Usability Heuristics</a> as a guide.</li>`],
      activities: [],
      assignments: [],
    },
  },
  {
    id: createEventId(),
    title: '3.4 - Insight Translation',
    start: addDays(todayStr, 3),
    allDay: true,
    classNames: `eventClass normal`,
    extendedProps: {
      isCheckpoint: true,
      desc: `By the end of this checkpoint, you will be able to find and create foundational documents that will help you organize your research results. You will also be able to prioritize and communicate the most important decision-influencing insights that you gleaned from this research.`,
      url: `https://www.uxuiopen.com/docs/user_centered_design/insight`,
      activities: [],
      assignments: [],
    },
  },
  {
    id: createEventId(),
    title: '3.5 - Ongoing Evaluation',
    start: addDays(todayStr, 4),
    allDay: true,
    classNames: `eventClass normal`,
    extendedProps: {
      isCheckpoint: true,
      desc: `By the end of this checkpoint, you will be able to recognize and list multiple methods that can be used to evaluate the performance of your design.`,
      url: `https://www.uxuiopen.com/docs/user_centered_design/evaluation`,
      activities: [],
      assignments: [],
    },
  },
  {
    id: createEventId(),
    title: '3.6 Assignment',
    start: addDays(todayStr, 4),
    allDay: true,
    classNames: `eventClass gold`,
    extendedProps: {
      isCheckpoint: true,
      desc: `Analytics, goals, and KPIs`,
      url: `https://www.uxuiopen.com/docs/user_centered_design/evaluation`,
      activities: [],
      assignments: [{
        name: 'Self Graded',
        url: `https://www.uxuiopen.com/docs/user_centered_design/assignment`
      }],
    },
  },
  {
    id: createEventId(),
    title: '4.1 Principles',
    start: addDays(todayStr, 4),
    allDay: true,
    classNames: 'eventClass normal',
    extendedProps: {
      isCheckpoint: true,
      desc: `By the end of this checkpoint, you will be able to identify the principles and elements of visual design and describe how they are used to create positive user experiences.`,
      url: `https://www.uxuiopen.com/docs/visual_design/principles`,
      guides: [],
      activities: [],
      assignments: [],
    },
  },
  {
    id: createEventId(),
    title: '4.2 Accessible Design',
    start: addDays(todayStr, 4),
    allDay: true,
    classNames: `eventClass normal`,
    extendedProps: {
      isCheckpoint: true,
      desc: `By the end of this checkpoint, you will be able to describe how the look and feel of a product or service can affect its usability and accessibility.`,
      url: `https://www.uxuiopen.com/docs/visual_design/accessible`,
      guides: [],
      activities: [{
        name: 'WAVE acivity',
        url: `https://www.uxuiopen.com/docs/visual_design/accessible#activity-`
      }],
      assignments: [],
    },
  },
  {
    id: createEventId(),
    title: '4.3 Designing Experiences',
    start: addDays(todayStr, 7),
    allDay: true,
    classNames: `eventClass normal`,
    extendedProps: {
      isCheckpoint: true,
      desc: `By the end of this checkpoint, you will be able to describe methods for using empathy as a designer. You will also be able to use the five senses theory to evaluate designs.`,
      url: `https://www.uxuiopen.com/docs/visual_design/memorable_ex`,
      guides: [],
      activities: [{
        name: 'Senses theory graph',
        url: `https://www.uxuiopen.com/docs/visual_design/memorable_ex#activity-`
      }],
      assignments: [],
    },
  },
  {
    id: createEventId(),
    title: '4.4 Mood Boards',
    start: addDays(todayStr, 7),
    allDay: true,
    classNames: `eventClass normal`,
    extendedProps: {
      isCheckpoint: true,
      desc: `By the end of this checkpoint, you will be able to identify basic design deliverables and practice creating deliverables with industry-standard tools.`,
      url: `https://www.uxuiopen.com/docs/visual_design/mood_boards`,
      guides: [],
      activities: [],
      assignments: [],
    },
  },
  {
    id: createEventId(),
    title: '4.5 Visual Design Foundations',
    start: addDays(todayStr, 7),
    allDay: true,
    classNames: `eventClass normal`,
    extendedProps: {
      isCheckpoint: true,
      desc: `By the end of this checkpoint, you will be able to use industry-standard tools to create a simple style tile. You will also demonstrate your understanding of design principles and deliverables.`,
      url: `https://www.uxuiopen.com/docs/visual_design/visual_design_fd`,
      guides: [],
      activities: [],
      assignments: [],
    },
  },
  {
    id: createEventId(),
    title: '4.6 End Of Fundamentals',
    start: addDays(todayStr, 7),
    allDay: true,
    classNames: `eventClass gold`,
    extendedProps: {
      isCheckpoint: true,
      desc: `By the end of this checkpoint, you will be able to self-evaluate your understanding of the design process, UX design and research, UI and visual design, and usability. You will begin preparations for the next skills and concepts that you'll learn, and get ready to start building some real-world products.`,
      url: `https://www.uxuiopen.com/docs/visual_design/graduating`,
      guides: [],
      activities: [],
      assignments: [{
        name: 'Self Evaluated',
        url: `https://www.uxuiopen.com/docs/visual_design/graduating#assignment-`
      }],
    },
  },
  {
    id: createEventId(),
    title: 'Mentor Call 2',
    start: addDays(todayStr, 8),
    allDay: true,
    classNames: `eventClass mentor`,
    extendedProps: {
      isCheckpoint: true,
      desc: 'Visit ADP list and schedule your mentor call. Ensure you let the mentor know in advance the doubts you have. Jot down a few questions you have in mind that you would want to ask them. Share the uxuiopen link if necessary for them to know what doubts you have',
      url: `https://www.uxuiopen.com/docs/visual_design/graduating/#mentor-call-2-`,
      activities: [],
      assignments: [],
    },
  },
  {
    id: createEventId(),
    title: 'CATCH UP',
    start: addDays(todayStr, 8),
    allDay: true,
    classNames: `eventClass green`,
    extendedProps: {
      isCheckpoint: true,
      desc: `Take rest. Catch up if you've fallen behind schedule. The coming weeks are going to be intense`,
      url: 'https://www.youtube.com/watch?v=dQw4w9WgXcQ',
      activities: [],
      assignments: [],
    },
  },
  {
    id: createEventId(),
    title: '5.1 Dicovery Workshop',
    start: addDays(todayStr, 9),
    allDay: true,
    classNames: `eventClass gold`,
    extendedProps: {
      isCheckpoint: true,
      desc: `By the end of this checkpoint, you will be able to identify information to be collected from the UX discovery client or team ideations, scope definitions, and goal-setting workshops. You will also be able to describe the goals for the UX intensive course project, a bus app.`,
      url: `https://www.uxuiopen.com/docs/uxi_discover/workshop/`,
      guides: [],
      activities: [],
      assignments: [{
        name: 'Bus App Project',
        url: `https://www.uxuiopen.com/docs/uxi_discover/workshop/#bus-app-project-overview-`
      }],
    },
  },
  {
    id: createEventId(),
    title: '5.2 Research Methods',
    start: addDays(todayStr, 9),
    allDay: true,
    classNames: `eventClass normal`,
    extendedProps: {
      isCheckpoint: true,
      desc: `By the end of this checkpoint, you will be able to identify common user experience research methods and structure a simple research plan.`,
      url: `https://www.uxuiopen.com/docs/uxi_discover/research_methods/`,
      guides: [],
      activities: [],
      assignments: [],
    },
  },
  {
    id: createEventId(),
    title: '5.3 Competitive Analysis',
    start: addDays(todayStr, 9),
    allDay: true,
    classNames: `eventClass normal`,
    extendedProps: {
      isCheckpoint: true,
      desc: `By the end of this checkpoint, you will be able to perform a competitive analysis and explain why this activity is a vital part of the design process.`,
      url: `https://www.uxuiopen.com/docs/uxi_discover/competitive_analysis/`,
      guides: [],
      activities: [],
      assignments: [],
    },
  },
  {
    id: createEventId(),
    title: '5.4 User Surveys',
    start: addDays(todayStr, 9),
    allDay: true,
    classNames: `eventClass normal`,
    extendedProps: {
      isCheckpoint: true,
      desc: `By the end of this checkpoint, you will be able to create surveys that efficiently collect data from potential users.`,
      url: `https://www.uxuiopen.com/docs/uxi_discover/user_surveys/`,
      guides: [],
      activities: [],
      assignments: [],
    },
  },
  {
    id: createEventId(),
    title: '5.5 Conducting Interviews',
    start: addDays(todayStr, 10),
    allDay: true,
    classNames: `eventClass gold`,
    extendedProps: {
      isCheckpoint: true,
      desc: `By the end of this checkpoint, you will be able to prepare for and execute research interviews to better understand the needs of your target audience`,
      url: `https://www.uxuiopen.com/docs/uxi_discover/interviws/`,
      guides: [],
      activities: [],
      assignments: [{
        name: 'Resarch Interviews',
        url: `https://www.uxuiopen.com/docs/uxi_discover/interviws/#research-interviews`
      }],
    },
  },
  {
    id: createEventId(),
    title: '5.6 User Personas',
    start: addDays(todayStr, 10),
    allDay: true,
    classNames: `eventClass normal`,
    extendedProps: {
      isCheckpoint: true,
      desc: `By the end of this checkpoint, you will be able to use results from user surveys and interviews to generate personas.`,
      url: `https://www.uxuiopen.com/docs/uxi_discover/personas/`,
      guides: [],
      activities: [],
      assignments: [],
    },
  },
  {
    id: createEventId(),
    title: '5.7 Experience Mapping',
    start: addDays(todayStr, 10),
    allDay: true,
    classNames: `eventClass normal`,
    extendedProps: {
      isCheckpoint: true,
      desc: `By the end of this checkpoint, you will be able to use results from user surveys and interviews to generate personas.`,
      url: `https://www.uxuiopen.com/docs/uxi_discover/idea_mapping/`,
      guides: [],
      activities: [],
      assignments: [],
    },
  },
  {
    id: createEventId(),
    title: '5.8 Sharing Insights',
    start: addDays(todayStr, 10),
    allDay: true,
    classNames: `eventClass normal`,
    extendedProps: {
      isCheckpoint: true,
      desc: `By the end of this checkpoint, you will be able to extract and prioritize what you learn from your research. You'll also be able to turn your research outputs into an attractive and informative presentation.`,
      url: `https://www.uxuiopen.com/docs/uxi_discover/sharing_insights/`,
      guides: [],
      activities: [],
      assignments: [],
    },
  },
  {
    id: createEventId(),
    title: '6.1 Define Phase',
    start: addDays(todayStr, 11),
    allDay: true,
    classNames: `eventClass gold`,
    extendedProps: {
      isCheckpoint: true,
      desc: `By the end of the checkpoint, you will be able to explain the purpose of the define phase and use the research information, requirements, and other specifications to create user stories.`,
      url: `https://www.uxuiopen.com/docs/uxi_define/intro/`,
      guides: [],
      activities: [],
      assignments: [{
        name: 'Assignment 6: User stories',
        url: `https://www.uxuiopen.com/docs/uxi_define/intro/#assignment-6-`
      }],
    },
  },
  {
    id: createEventId(),
    title: '6.2 User Flows',
    start: addDays(todayStr, 11),
    allDay: true,
    classNames: `eventClass graded`,
    extendedProps: {
      isCheckpoint: true,
      isGraded: true,
      desc: `By the end of the checkpoint, you will be able to use the research information, requirements, and other specifications to create a user flow for a project.`,
      url: `https://www.uxuiopen.com/docs/uxi_define/user_flows/`,
      guides: [],
      activities: [],
      assignments: [{
        name: 'Assignment 7: Drafting user flows',
        url: `https://www.uxuiopen.com/docs/uxi_define/user_flows/#assignment-7-`
      }],
    },
  },
  {
    id: createEventId(),
    title: 'Mentor Call 3',
    start: addDays(todayStr, 11),
    allDay: true,
    classNames: `eventClass mentor`,
    extendedProps: {
      isCheckpoint: true,
      desc: 'Visit ADP list and schedule your mentor call. Ensure you let the mentor know in advance the doubts you have. Jot down a few questions you have in mind that you would want to ask them. Share the uxuiopen link if necessary for them to know what doubts you have',
      url: `https://www.uxuiopen.com/docs/uxi_define/user_flows/#mentor-call-3-`,
      activities: [],
      assignments: [],
    },
  },
  {
    id: createEventId(),
    title: '6.3 Content Strategy',
    start: addDays(todayStr, 11),
    allDay: true,
    classNames: `eventClass gold`,
    extendedProps: {
      isCheckpoint: true,
      desc: `By the end of the checkpoint, you will be able to use the research you've conducted, requirements, and other specifications to define site maps.`,
      url: `https://www.uxuiopen.com/docs/uxi_define/content_strategy/`,
      guides: [],
      activities: [],
      assignments: [{
        name: 'Assignment 8: Site map',
        url: `https://www.uxuiopen.com/docs/uxi_define/content_strategy/#assignment-8-`
      }],
    },
  },
  {
    id: createEventId(),
    title: '6.4 Wireframes',
    start: addDays(todayStr, 14),
    allDay: true,
    classNames: `eventClass gold`,
    extendedProps: {
      isCheckpoint: true,
      desc: `By the end of the checkpoint, you will be able to use the research information, requirements, and other specifications to create wireframes for a project.`,
      url: `https://www.uxuiopen.com/docs/uxi_define/wireframes/`,
      guides: [],
      activities: [],
      assignments: [{
        name: 'Assignment 9: Wireframes',
        url: `https://www.uxuiopen.com/docs/uxi_define/wireframes/#assignment-9-`
      }],
    },
  },
  {
    id: createEventId(),
    title: '6.5 Clickable Prototype',
    start: addDays(todayStr, 14),
    allDay: true,
    classNames: `eventClass normal`,
    extendedProps: {
      isCheckpoint: true,
      desc: `By the end of the checkpoint, you will be able to use the research information, requirements, and other specifications to define a clickable prototype.`,
      url: `https://www.uxuiopen.com/docs/uxi_define/clickable_prototype/`,
      guides: [],
      activities: [],
      assignments: [],
    },
  },
  {
    id: createEventId(),
    title: '6.6 Usability Test',
    start: addDays(todayStr, 14),
    allDay: true,
    classNames: `eventClass normal`,
    extendedProps: {
      isCheckpoint: true,
      desc: `By the end of the checkpoint, you will be able to refine your prototype so that it incorporates stakeholder and user feedback.`,
      url: `https://www.uxuiopen.com/docs/uxi_define/usability/`,
      guides: [],
      activities: [],
      assignments: [],
    },
  },
  {
    id: createEventId(),
    title: '7.1 Develop Intro',
    start: addDays(todayStr, 14),
    allDay: true,
    classNames: `eventClass normal`,
    extendedProps: {
      isCheckpoint: true,
      desc: `By the end of this checkpoint, you will be able to describe the overall work to be done in the develop phase. You will also be able to identify strong designs for inspiration.`,
      url: `https://www.uxuiopen.com/docs/uxi_develop/intro/`,
      guides: [],
      activities: [{
        name: 'Design inspiration',
        url: `https://www.uxuiopen.com/docs/uxi_develop/intro/#activity-`
      }],
      assignments: [],
    },
  },
  {
    id: createEventId(),
    title: '7.2 Design Systems',
    start: addDays(todayStr, 14),
    allDay: true,
    classNames: `eventClass normal`,
    extendedProps: {
      isCheckpoint: true,
      desc: `By the end of the checkpoint, you will be able to argue for the importance of a design system and describe some popular design systems.`,
      url: `https://www.uxuiopen.com/docs/uxi_develop/design_systems/`,
      guides: [],
      activities: [],
      assignments: [],
    },
  },
  {
    id: createEventId(),
    title: '7.3 Best Practices',
    start: addDays(todayStr, 15),
    allDay: true,
    classNames: `eventClass normal`,
    extendedProps: {
      isCheckpoint: true,
      desc: `By the end of the checkpoint, you will be able to define mental models and describe best practices for visual design.`,
      url: `https://www.uxuiopen.com/docs/uxi_develop/best_practices/`,
      guides: [],
      activities: [],
      assignments: [],
    },
  },
  {
    id: createEventId(),
    title: '7.4 Design Requirements',
    start: addDays(todayStr, 15),
    allDay: true,
    classNames: `eventClass normal`,
    extendedProps: {
      isCheckpoint: true,
      desc: `By the end of the checkpoint, you will be able to evaluate viewport requirements and apply responsive design methods to your own design process.`,
      url: `https://www.uxuiopen.com/docs/uxi_develop/design_requirements/`,
      guides: [],
      activities: [],
      assignments: [],
    },
  },
  {
    id: createEventId(),
    title: '7.5 Developing Layout',
    start: addDays(todayStr, 15),
    allDay: true,
    classNames: `eventClass normal`,
    extendedProps: {
      isCheckpoint: true,
      desc: `By the end of the checkpoint, you will be able to apply the concepts of hierarchy, space, and grid systems to improve the functionality of layout designs.`,
      url: `https://www.uxuiopen.com/docs/uxi_develop/developing_layout/`,
      guides: [],
      activities: [],
      assignments: [],
    },
  },
  {
    id: createEventId(),
    title: '7.6 Branding',
    start: addDays(todayStr, 15),
    allDay: true,
    classNames: `eventClass gold`,
    extendedProps: {
      isCheckpoint: true,
      desc: `By the end of this checkpoint, you will apply your research, requirements, and other specifications to define a brand and create a mood board`,
      url: `https://www.uxuiopen.com/docs/uxi_develop/branding/`,
      guides: [],
      activities: [],
      assignments: [{
        name: 'Assignment 10: Mood Board',
        url: `https://www.uxuiopen.com/docs/uxi_develop/branding/#assignment-10-`
      }],
    },
  },
  {
    id: createEventId(),
    title: '7.7 Typography',
    start: addDays(todayStr, 16),
    allDay: true,
    classNames: `eventClass normal`,
    extendedProps: {
      isCheckpoint: true,
      desc: `By the end of the checkpoint, you will apply your research, requirements, and other specifications to define a brand and create a typographical guide.`,
      url: `https://www.uxuiopen.com/docs/uxi_develop/typography/`,
      guides: [],
      activities: [],
      assignments: [],
    },
  },
  {
    id: createEventId(),
    title: '7.8 Color Palette',
    start: addDays(todayStr, 16),
    allDay: true,
    classNames: `eventClass normal`,
    extendedProps: {
      isCheckpoint: true,
      desc: `By the end of the checkpoint, you will be able to apply color theory to choosing an appropriate color palette for a project.`,
      url: `https://www.uxuiopen.com/docs/uxi_develop/color/`,
      guides: [],
      activities: [{
        name: 'Bus App color palette',
        url: `https://www.uxuiopen.com/docs/uxi_develop/color/#activity-`
      }],
      assignments: [],
    },
  },
  {
    id: createEventId(),
    title: '7.9 Logo',
    start: addDays(todayStr, 16),
    allDay: true,
    classNames: `eventClass gold`,
    extendedProps: {
      isCheckpoint: true,
      desc: `By the end of the checkpoint, you will be able to sketch and create a digital logo for a project and generate a name for an app`,
      url: `https://www.uxuiopen.com/docs/uxi_develop/logo/`,
      guides: [],
      activities: [],
      assignments: [{
        name: 'Assignment 11: Logo',
        url: `https://www.uxuiopen.com/docs/uxi_develop/logo/#assignment-11-`
      }],
    },
  },
  {
    id: createEventId(),
    title: '7.10 Hifi Mockup',
    start: addDays(todayStr, 17),
    allDay: true,
    classNames: `eventClass gold`,
    extendedProps: {
      isCheckpoint: true,
      desc: `By the end of the checkpoint, you will be able to create high-fidelity deliverables.`,
      url: `https://www.uxuiopen.com/docs/uxi_develop/hifi_mockups/`,
      guides: [],
      activities: [],
      assignments: [{
        name: 'Assignment 12',
        url: `https://www.uxuiopen.com/docs/uxi_develop/hifi_mockups/#creating-a-high-fidelity-prototype`
      }],
    },
  },
  {
    id: createEventId(),
    title: 'Mentor Call 4',
    start: addDays(todayStr, 17),
    allDay: true,
    classNames: `eventClass mentor`,
    extendedProps: {
      isCheckpoint: true,
      desc: 'Visit ADP list and schedule your mentor call. Ensure you let the mentor know in advance the doubts you have. Jot down a few questions you have in mind that you would want to ask them. Share the uxuiopen link if necessary for them to know what doubts you have',
      url: `https://www.uxuiopen.com/docs/uxi_define/user_flows/#mentor-call-3-`,
      activities: [],
      assignments: [],
    },
  },
  {
    id: createEventId(),
    title: '8.1 Deliver',
    start: addDays(todayStr, 18),
    allDay: true,
    classNames: `eventClass normal`,
    extendedProps: {
      isCheckpoint: true,
      desc: `By the end of this checkpoint, you will be able to describe the overall work to be done in the deliver phase.`,
      url: `https://www.uxuiopen.com/docs/uxi_deliver/intro/`,
      guides: [],
      activities: [{
        name: 'List your deliverables',
        url: `https://www.uxuiopen.com/docs/uxi_deliver/intro/#activity-`
      }],
      assignments: [],
    },
  },
  {
    id: createEventId(),
    title: '8.2 Prototype',
    start: addDays(todayStr, 18),
    allDay: true,
    classNames: `eventClass normal`,
    extendedProps: {
      isCheckpoint: true,
      desc: `By the end of the checkpoint, you will be able to refine your clickable prototype for your bus project.`,
      url: `https://www.uxuiopen.com/docs/uxi_deliver/prototype/`,
      guides: [],
      activities: [],
      assignments: [],
    },
  },
  {
    id: createEventId(),
    title: '8.3 Usability Tests',
    start: addDays(todayStr, 18),
    allDay: true,
    classNames: `eventClass normal`,
    extendedProps: {
      isCheckpoint: true,
      desc: `By the end of the checkpoint, you will know how to test your clickable prototype for usability.`,
      url: `https://www.uxuiopen.com/docs/uxi_deliver/usability_tests/`,
      guides: [],
      activities: [],
      assignments: [],
    },
  },
  {
    id: createEventId(),
    title: '8.4 Usability Reports',
    start: addDays(todayStr, 18),
    allDay: true,
    classNames: `eventClass normal`,
    extendedProps: {
      isCheckpoint: true,
      desc: `By the end of the checkpoint, you will know how to create usability reports and communicate findings to stakeholders.`,
      url: `https://www.uxuiopen.com/docs/uxi_deliver/usability_reports/`,
      guides: [],
      activities: [],
      assignments: [],
    },
  },
  {
    id: createEventId(),
    title: '8.5 Delivering Assets',
    start: addDays(todayStr, 21),
    allDay: true,
    classNames: `eventClass normal`,
    extendedProps: {
      isCheckpoint: true,
      desc: `By the end of the checkpoint, you will discover how to deliver design elements like logos, icons, and fonts. In addition, this checkpoint provides tips for asset organization.`,
      url: `https://www.uxuiopen.com/docs/uxi_deliver/delivering_assets/`,
      guides: [],
      activities: [],
      assignments: [],
    },
  },
  {
    id: createEventId(),
    title: '8.6 Case Study',
    start: addDays(todayStr, 21),
    allDay: true,
    classNames: `eventClass normal`,
    extendedProps: {
      isCheckpoint: true,
      desc: `By the end of the checkpoint, you will be able to draft a case study for your portfolio, demonstrating your design process and thinking.`,
      url: `https://www.uxuiopen.com/docs/uxi_deliver/case_study/`,
      guides: [],
      activities: [],
      assignments: [],
    },
  },
  {
    id: createEventId(),
    title: '8.7 Presentation',
    start: addDays(todayStr, 21),
    allDay: true,
    classNames: `eventClass normal`,
    extendedProps: {
      isCheckpoint: true,
      desc: `By the end of this checkpoint, you will be able to present your work persuasively and confidently.`,
      url: `https://www.uxuiopen.com/docs/uxi_deliver/presentation/`,
      guides: [],
      activities: [],
      assignments: [],
    },
  },
  {
    id: createEventId(),
    title: 'CATCH UP',
    start: addDays(todayStr, 22),
    allDay: true,
    classNames: `eventClass green`,
    extendedProps: {
      isCheckpoint: true,
      desc: `Take rest. Catch up if you've fallen behind schedule.`,
      url: 'https://www.youtube.com/watch?v=dQw4w9WgXcQ',
      activities: [],
      assignments: [],
    },
  },
  {
    id: createEventId(),
    title: '9.1 UX teams',
    start: addDays(todayStr, 23),
    allDay: true,
    classNames: `eventClass normal`,
    extendedProps: {
      isCheckpoint: true,
      desc: `By the end of this checkpoint, you will be able to compare and contrast different roles on a real-world UX team and analyze how they function within the greater business organization.`,
      url: `https://www.uxuiopen.com/docs/defining_a_product/01/`,
      guides: [],
      activities: [],
      assignments: [],
    },
  },
  {
    id: createEventId(),
    title: '9.2 Requirements',
    start: addDays(todayStr, 23),
    allDay: true,
    classNames: `eventClass normal`,
    extendedProps: {
      isCheckpoint: true,
      desc: `By the end of this checkpoint, you will be able to measure team success through defining UX requirements and establishing parameters, recognizing the value of a design sprint, and applying this knowledge to your own app project.`,
      url: `https://www.uxuiopen.com/docs/defining_a_product/02/`,
      guides: [],
      activities: [],
      assignments: [],
    },
  },
  {
    id: createEventId(),
    title: '9.3 Interviewing',
    start: addDays(todayStr, 23),
    allDay: true,
    classNames: `eventClass normal`,
    extendedProps: {
      isCheckpoint: true,
      desc: `By the end of this checkpoint, you will be able to conduct efficient user research and define a target audience for your users.`,
      url: `https://www.uxuiopen.com/docs/defining_a_product/03/`,
      guides: [],
      activities: [],
      assignments: [],
    },
  },
  {
    id: createEventId(),
    title: '9.4 Pick A Target',
    start: addDays(todayStr, 23),
    allDay: true,
    classNames: `eventClass gold`,
    extendedProps: {
      isCheckpoint: true,
      desc: `By the end of this checkpoint, you will be able to compare and contrast different methods of product development and how it affects the day-to-day workflow on a UX team. You will pick a target to focus on during your design sprint.`,
      url: `https://www.uxuiopen.com/docs/defining_a_product/04/`,
      guides: [],
      activities: [],
      assignments: [{
        name: 'Self Graded: Sticky Notes',
        url: `https://www.uxuiopen.com/docs/defining_a_product/04/#assignment-`
      }],
    },
  },
  {
    id: createEventId(),
    title: '9.5 Archetypes',
    start: addDays(todayStr, 24),
    allDay: true,
    classNames: `eventClass normal`,
    extendedProps: {
      isCheckpoint: true,
      desc: `By the end of this checkpoint, you will be able to apply the concepts of archetypes to the team design process, visualizing the user journey with empathy maps and user journey maps.`,
      url: `https://www.uxuiopen.com/docs/defining_a_product/05/`,
      guides: [],
      activities: [{
        name: 'Map the UX',
        url: `https://www.uxuiopen.com/docs/defining_a_product/05/#activity-`
      }],
      assignments: [],
    },
  },
  {
    id: createEventId(),
    title: '9.6 Flowchart',
    start: addDays(todayStr, 24),
    allDay: true,
    classNames: `eventClass normal`,
    extendedProps: {
      isCheckpoint: true,
      desc: `By the end of this checkpoint, you will be able to analyze the value of a good interview and apply these values to your design sprint. You will be able to create a flowchart for your app project and write a script for interviewing your client.`,
      url: `https://www.uxuiopen.com/docs/defining_a_product/06/`,
      guides: [],
      activities: [{
        name: 'Flowchart Sketch',
        url: `https://www.uxuiopen.com/docs/defining_a_product/06/#activity-`
      }],
      assignments: [],
    },
  },
  {
    id: createEventId(),
    title: '9.7 Designer As DJ',
    start: addDays(todayStr, 24),
    allDay: true,
    classNames: `eventClass normal`,
    extendedProps: {
      isCheckpoint: true,
      desc: `By the end of this checkpoint, you will be able to review and evaluate the concept of remix by looking to existing design solutions as inspiration. As part of your design sprint, you will complete lightning demos for the app project.`,
      url: `https://www.uxuiopen.com/docs/defining_a_product/07/`,
      guides: [],
      activities: [{
        name: 'Lightning Demos',
        url: `https://www.uxuiopen.com/docs/defining_a_product/07/#activity-`
      }],
      assignments: [],
    },
  },
  {
    id: createEventId(),
    title: '9.8 Sketch',
    start: addDays(todayStr, 24),
    allDay: true,
    classNames: `eventClass gold`,
    extendedProps: {
      isCheckpoint: true,
      desc: `By the end of this checkpoint, you will be able to complete the four-step sketch process for a portion of your product for your client.`,
      url: `https://www.uxuiopen.com/docs/defining_a_product/08/`,
      guides: [],
      activities: [],
      assignments: [{
        name: 'Assignment 13: Four-step sketch',
        url: `https://www.uxuiopen.com/docs/defining_a_product/08/#assignment-13-`
      }],
    },
  },
  {
    id: createEventId(),
    title: '9.9 Extracting Insights',
    start: addDays(todayStr, 25),
    allDay: true,
    classNames: `eventClass normal`,
    extendedProps: {
      isCheckpoint: true,
      desc: `By the end of this checkpoint, you will be able to extract insights by applying the aesthetic-usability effect or attractiveness bias design principle to your design critique. This helps you make sense of the data you've gathered and the sketches you've made to prioritize an actionable strategy.`,
      url: `https://www.uxuiopen.com/docs/defining_a_product/09/`,
      guides: [],
      activities: [],
      assignments: [],
    },
  },
  {
    id: createEventId(),
    title: '9.10 Recruiting Users',
    start: addDays(todayStr, 25),
    allDay: true,
    classNames: `eventClass normal`,
    extendedProps: {
      isCheckpoint: true,
      desc: `By the end of this checkpoint, you will be able to evaluate the Pareto principle (or 80/20 rule) as it pertains to decision-making, recruit users for user testing, and craft wireframes for your app project.`,
      url: `https://www.uxuiopen.com/docs/defining_a_product/10/`,
      guides: [],
      activities: [{
        name: 'Research tasks',
        url: `https://www.uxuiopen.com/docs/defining_a_product/10/#activity-`
      }],
      assignments: [],
    },
  },
  {
    id: createEventId(),
    title: 'Mentor Call 5',
    start: addDays(todayStr, 25),
    allDay: true,
    classNames: `eventClass mentor`,
    extendedProps: {
      isCheckpoint: true,
      desc: 'Visit ADP list and schedule your mentor call. Ensure you let the mentor know in advance the doubts you have. Jot down a few questions you have in mind that you would want to ask them. Share the uxuiopen link if necessary for them to know what doubts you have',
      url: `https://www.uxuiopen.com/docs/defining_a_product/08/#mentor-call-5-`,
      activities: [],
      assignments: [],
    },
  },
  {
    id: createEventId(),
    title: '10.1 Accessibility',
    start: addDays(todayStr, 28),
    allDay: true,
    classNames: `eventClass normal`,
    extendedProps: {
      isCheckpoint: true,
      desc: `By the end of this checkpoint, you will be able to apply the concept of accessibility to the design process. You'll be able to visualize the user journey and contextualize prior sketches through storyboarding. You'll be able to piece together the sketches and user data that you've gathered.`,
      url: `https://www.uxuiopen.com/docs/prototyping/01/`,
      guides: [],
      activities: [],
      assignments: [],
    },
  },
  {
    id: createEventId(),
    title: '10.2 Oraganizing Content',
    start: addDays(todayStr, 28),
    allDay: true,
    classNames: `eventClass normal`,
    extendedProps: {
      isCheckpoint: true,
      desc: `By the end of this checkpoint, you will be able to step back and reflect on your work so far. You will evaluate the content as it aligns with the materials you've generated during this sprint..`,
      url: `https://www.uxuiopen.com/docs/prototyping/02/`,
      guides: [],
      activities: [],
      assignments: [],
    },
  },
  {
    id: createEventId(),
    title: '10.3 Paper Prototyping',
    start: addDays(todayStr, 28),
    allDay: true,
    classNames: `eventClass normal`,
    extendedProps: {
      isCheckpoint: true,
      desc: `By the end of this checkpoint, you will compare and contrast paper prototyping as a design tool. You'll be able to create a usable, testable paper prototype model. You'll use this paper prototype model as a sketch for your low fidelity prototype that you will initiate in a future checkpoint.`,
      url: `https://www.uxuiopen.com/docs/prototyping/03/`,
      guides: [],
      activities: [],
      assignments: [],
    },
  },
  {
    id: createEventId(),
    title: '10.4 Tools',
    start: addDays(todayStr, 28),
    allDay: true,
    classNames: `eventClass normal`,
    extendedProps: {
      isCheckpoint: true,
      desc: `By the end of this checkpoint, you will be able to create a plan for prototyping. You will evaluate and pick the right tools that you feel comfortable using to present the wireframes and storyboards to stakeholders.`,
      url: `https://www.uxuiopen.com/docs/prototyping/04/`,
      guides: [],
      activities: [],
      assignments: [],
    },
  },
  {
    id: createEventId(),
    title: '10.5 Trial Run',
    start: addDays(todayStr, 28),
    allDay: true,
    classNames: `eventClass normal`,
    extendedProps: {
      isCheckpoint: true,
      desc: `By the end of this checkpoint, you will be able to analyze the requirements for quantitative and qualitative user testing and apply them to the design process. This will help you be ready for the next user-testing week of your design sprint.`,
      url: `https://www.uxuiopen.com/docs/prototyping/05/`,
      guides: [],
      activities: [],
      assignments: [],
    },
  },
  {
    id: createEventId(),
    title: '10.6 User Testing',
    start: addDays(todayStr, 29),
    allDay: true,
    classNames: `eventClass normal`,
    extendedProps: {
      isCheckpoint: true,
      desc: `By the end of this checkpoint, you will be able to analyze good interview questions and the value of creating a testing guide. This will help you be ready for the user-testing phase of your design sprint.`,
      url: `https://www.uxuiopen.com/docs/prototyping/06/`,
      guides: [],
      activities: [],
      assignments: [],
    },
  },
  {
    id: createEventId(),
    title: '10.7 Prototype',
    start: addDays(todayStr, 29),
    allDay: true,
    classNames: `eventClass normal`,
    extendedProps: {
      isCheckpoint: true,
      desc: `By the end of the checkpoint, you will begin to piece together the sketches and user data you've gathered to create a real, client-facing prototype.`,
      url: `https://www.uxuiopen.com/docs/prototyping/07/`,
      guides: [],
      activities: [],
      assignments: [],
    },
  },
  {
    id: createEventId(),
    title: '10.8 More Prototyping',
    start: addDays(todayStr, 29),
    allDay: true,
    classNames: `eventClass normal`,
    extendedProps: {
      isCheckpoint: true,
      desc: `By the end of the checkpoint, you will be able to finish piecing together your sketches, wireframes, paper prototypes, and the user data you've gathered to complete your real and testable client-facing prototype.`,
      url: `https://www.uxuiopen.com/docs/prototyping/08/`,
      guides: [],
      activities: [],
      assignments: [],
    },
  },
  {
    id: createEventId(),
    title: '10.9 Internal Test',
    start: addDays(todayStr, 29),
    allDay: true,
    classNames: `eventClass normal`,
    extendedProps: {
      isCheckpoint: true,
      desc: `By the end of this checkpoint, you will be able to edit your low fidelity prototype that you've built and conduct a trial run.`,
      url: `https://www.uxuiopen.com/docs/prototyping/09/`,
      guides: [],
      activities: [],
      assignments: [],
    },
  },
  {
    id: createEventId(),
    title: '10.10 Stitching It',
    start: addDays(todayStr, 29),
    allDay: true,
    classNames: `eventClass normal`,
    extendedProps: {
      isCheckpoint: true,
      desc: `By the end of this checkpoint, you will be able to analyze and apply the concept of heuristic evaluation to the team design process. You'll evaluate and improve your prototypes so you can be ready for the final user-testing day of your design sprint.`,
      url: `https://www.uxuiopen.com/docs/prototyping/10/`,
      guides: [],
      activities: [],
      assignments: [],
    },
  },
  {
    id: createEventId(),
    title: '11.1 to 11.4',
    start: addDays(todayStr, 30),
    allDay: true,
    classNames: `eventClass normal`,
    extendedProps: {
      isCheckpoint: true,
      desc: `Finish checkpoints 11.1 to 11.4`,
      url: `https://www.uxuiopen.com/docs/presenting/01/`,
      guides: [],
      activities: [],
      assignments: [],
    },
  },
  {
    id: createEventId(),
    title: '11.5 Stakeholders',
    start: addDays(todayStr, 30),
    allDay: true,
    classNames: `eventClass normal`,
    extendedProps: {
      isCheckpoint: true,
      desc: `By the end of this checkpoint, you will be able to present your app project to your stakeholder for feedback.`,
      url: `https://www.uxuiopen.com/docs/presenting/05/`,
      guides: [],
      activities: [],
      assignments: [],
    },
  },
  {
    id: createEventId(),
    title: '11.6 Planning For Dev',
    start: addDays(todayStr, 30),
    allDay: true,
    classNames: `eventClass normal`,
    extendedProps: {
      isCheckpoint: true,
      desc: `By the end of this checkpoint, you will begin planning for your the client presentation with an eye toward final product development, launch, and release`,
      url: `https://www.uxuiopen.com/docs/presenting/06/`,
      guides: [],
      activities: [],
      assignments: [],
    },
  },
  {
    id: createEventId(),
    title: '11.7 Presentation',
    start: addDays(todayStr, 31),
    allDay: true,
    classNames: `eventClass graded`,
    extendedProps: {
      isCheckpoint: true,
      isGraded: true,
      desc: `By the end of this checkpoint, you will be able to amend your presentation by incorporating stakeholder feedback and explaining your rationale behind the changes made.`,
      url: `https://www.uxuiopen.com/docs/presenting/07/`,
      guides: [],
      activities: [],
      assignments: [{
        name: 'Assignment 14: Client presentation',
        url: `https://www.uxuiopen.com/docs/presenting/07/#assignment-14-`
      }],
    },
  },
  {
    id: createEventId(),
    title: '11.8 External Presentation',
    start: addDays(todayStr, 31),
    allDay: true,
    classNames: `eventClass normal`,
    extendedProps: {
      isCheckpoint: true,
      desc: `By the end of this checkpoint, you will be able to create an external client presentation that you can pitch to your client in a confident, professional, and persuasive manner`,
      url: `https://www.uxuiopen.com/docs/presenting/08/`,
      guides: [],
      activities: [],
      assignments: [],
    },
  },
  {
    id: createEventId(),
    title: '11.9 Tour Of Trends',
    start: addDays(todayStr, 31),
    allDay: true,
    classNames: `eventClass normal`,
    extendedProps: {
      isCheckpoint: true,
      desc: `By the end of the checkpoint, you will be able to compare and contrast different roles on a real-world UX team. You'll also be able to analyze the current job market, including trends that affect how you search for jobs and grow your career`,
      url: `https://www.uxuiopen.com/docs/presenting/09/`,
      guides: [],
      activities: [],
      assignments: [],
    },
  },
  {
    id: createEventId(),
    title: '11.10 Real-World Team',
    start: addDays(todayStr, 31),
    allDay: true,
    classNames: `eventClass gold`,
    extendedProps: {
      isCheckpoint: true,
      desc: `By the end of this checkpoint, you can compare and contrast different ways to build an authentic network of UX professional relationships. You will build your portfolio and prepare for job interviews.`,
      url: `https://www.uxuiopen.com/docs/presenting/10/`,
      guides: [],
      activities: [],
      assignments: [{
        name: 'Interview Designers',
        url: `https://www.uxuiopen.com/docs/presenting/10/#assignment--`
      }],
    },
  },
  {
    id: createEventId(),
    title: 'CATCH UP',
    start: addDays(todayStr, 32),
    allDay: true,
    classNames: `eventClass green`,
    extendedProps: {
      isCheckpoint: true,
      desc: `Take rest. Catch up if you've fallen behind schedule. The coming weeks are going to be intense`,
      url: 'https://www.youtube.com/watch?v=dQw4w9WgXcQ',
      activities: [],
      assignments: [],
    },
  },
  {
    id: createEventId(),
    title: 'Mentor Call 6',
    start: addDays(todayStr, 32),
    allDay: true,
    classNames: `eventClass mentor`,
    extendedProps: {
      isCheckpoint: true,
      desc: 'Visit ADP list and schedule your mentor call. Ensure you let the mentor know in advance the doubts you have. Jot down a few questions you have in mind that you would want to ask them. Share the uxuiopen link if necessary for them to know what doubts you have',
      url: `https://www.uxuiopen.com/docs/presenting/10/#mentor-call-6-`,
      activities: [],
      assignments: [],
    },
  },
  {
    id: createEventId(),
    title: '12.1 Your Work',
    start: addDays(todayStr, 35),
    allDay: true,
    classNames: `eventClass portfolio`,
    extendedProps: {
      isCheckpoint: true,
      desc: `By the end of this checkpoint, you will be able to identify the important elements of a portfolio.`,
      url: `https://www.uxuiopen.com/docs/portfolio1/01/`,
      guides: [],
      activities: [],
      assignments: [],
    },
  },
  {
    id: createEventId(),
    title: '12.2 Creating A Portfolio',
    start: addDays(todayStr, 35),
    allDay: true,
    classNames: `eventClass portfolio`,
    extendedProps: {
      isCheckpoint: true,
      desc: `By the end of this checkpoint, you will begin to implement the foundation of your portfolio.`,
      url: `https://www.uxuiopen.com/docs/portfolio1/02/`,
      guides: [],
      activities: [],
      assignments: [],
    },
  },
  {
    id: createEventId(),
    title: '12.3 Case Studies',
    start: addDays(todayStr, 35),
    allDay: true,
    classNames: `eventClass portfolio`,
    extendedProps: {
      isCheckpoint: true,
      desc: `By the end of this checkpoint, you will be able to identify and curate the appropriate assets for a case study.`,
      url: `https://www.uxuiopen.com/docs/portfolio1/03/`,
      guides: [],
      activities: [{
        name: 'Content strategy',
        url: `https://www.uxuiopen.com/docs/portfolio1/03/#activity-`
      }],
      assignments: [],
    },
  },
  {
    id: createEventId(),
    title: '12.4 Sketching',
    start: addDays(todayStr, 35),
    allDay: true,
    classNames: `eventClass gold`,
    extendedProps: {
      isCheckpoint: true,
      desc: `By the end of this checkpoint, you will be able to sketch layout solutions for your portfol`,
      url: `https://www.uxuiopen.com/docs/portfolio1/04/`,
      guides: [],
      activities: [],
      assignments: [{
        name: 'Assignment 15: Portfolio Sktches',
        url: `https://www.uxuiopen.com/docs/portfolio1/04/#assignment-15-`
      }],
    },
  },
  {
    id: createEventId(),
    title: '12.5 Home Page',
    start: addDays(todayStr, 35),
    allDay: true,
    classNames: `eventClass portfolio`,
    extendedProps: {
      isCheckpoint: true,
      desc: `By the end of this checkpoint, you will be able to create a wireframe of your portfolio's home page and identify structural components to carry forward in the rest of your portfolio.`,
      url: `https://www.uxuiopen.com/docs/portfolio1/05/`,
      guides: [],
      activities: [{
        name: 'Portfolio Wireframe: Home',
        url: `https://www.uxuiopen.com/docs/portfolio1/05/#activity-`
      }],
      assignments: [],
    },
  },
  {
    id: createEventId(),
    title: '12.6 About Page',
    start: addDays(todayStr, 36),
    allDay: true,
    classNames: `eventClass portfolio`,
    extendedProps: {
      isCheckpoint: true,
      desc: `By the end of this checkpoint, you will be able to create a wireframe for your case study that can serve as a template for other pages.`,
      url: `https://www.uxuiopen.com/docs/portfolio1/06/`,
      guides: [],
      activities: [{
        name: 'Portfolio Wireframe: About',
        url: `https://www.uxuiopen.com/docs/portfolio1/06/#activity-`
      }],
      assignments: [],
    },
  },
  {
    id: createEventId(),
    title: '12.7 Biography Writing',
    start: addDays(todayStr, 36),
    allDay: true,
    classNames: `eventClass portfolio`,
    extendedProps: {
      isCheckpoint: true,
      desc: `By the end of this checkpoint, you will be able to identify critical moments that resulted in you taking the path to becoming a designer in order to write a compelling biography`,
      url: `https://www.uxuiopen.com/docs/portfolio1/07/`,
      guides: [],
      activities: [],
      assignments: [],
    },
  },
  {
    id: createEventId(),
    title: '12.8 Publishing',
    start: addDays(todayStr, 36),
    allDay: true,
    classNames: `eventClass portfolio`,
    extendedProps: {
      isCheckpoint: true,
      desc: `By the end of this checkpoint, you will be able to publish the foundation of your portfolio online.`,
      url: `https://www.uxuiopen.com/docs/portfolio1/08/`,
      guides: [],
      activities: [],
      assignments: [],
    },
  },
  {
    id: createEventId(),
    title: '12.8 Publishing',
    start: addDays(todayStr, 36),
    allDay: true,
    classNames: `eventClass gold`,
    extendedProps: {
      isCheckpoint: true,
      desc: `By the end of this checkpoint, you will be able to publish the foundation of your portfolio online.`,
      url: `https://www.uxuiopen.com/docs/portfolio1/09/`,
      guides: [],
      activities: [],
      assignments: [{
        name: 'Assignment 16: Branding',
        url: `https://www.uxuiopen.com/docs/portfolio1/09/#assignment-16-`
      }],
    },
  },
  {
    id: createEventId(),
    title: '13 User Research I',
    start: addDays(todayStr, 37),
    allDay: true,
    classNames: `eventClass normal`,
    extendedProps: {
      isCheckpoint: true,
      desc: `By the end of this checkpoint, you will be able to identify typical job roles and responsibilities of a user experience researcher and choose an appropriate website to study for your project, which will be a comparative and heuristic analysis.`,
      url: `https://www.uxuiopen.com/docs/category/13---user-research-i/`,
      guides: [],
      activities: [],
      assignments: [],
    },
  },
  {
    id: createEventId(),
    title: '14 Visual Design I',
    start: addDays(todayStr, 37),
    allDay: true,
    classNames: `eventClass gold`,
    extendedProps: {
      isCheckpoint: true,
      desc: `By the end of the checkpoint, you will review how the visual design of a product's user interface fits within the design process, evaluate some new methods of content personalization and adaptive UI, and apply these techniques to the user interface you are being asked to design for this module.`,
      url: `https://www.uxuiopen.com/docs/specialization_visual1/01/`,
      guides: [],
      activities: [],
      assignments: [{
          name: 'Self Graded: Plan the UI',
          url: `https://www.uxuiopen.com/docs/specialization_visual1/01/#assignment-`
        },
        {
          name: 'Self Graded: Create the UI',
          url: `https://www.uxuiopen.com/docs/specialization_visual1/02/#assignment-`
        }
      ],
    },
  },

  {
    id: createEventId(),
    title: 'Mentor Call 7',
    start: addDays(todayStr, 38),
    allDay: true,
    classNames: `eventClass mentor`,
    extendedProps: {
      isCheckpoint: true,
      desc: 'Visit ADP list and schedule your mentor call. Ensure you let the mentor know in advance the doubts you have. Jot down a few questions you have in mind that you would want to ask them. Share the uxuiopen link if necessary for them to know what doubts you have',
      url: `https://www.uxuiopen.com/docs/presenting/10/#mentor-call-6-`,
      activities: [],
      assignments: [],
    },
  },
  {
    id: createEventId(),
    title: '14.3 Viewport Requirements',
    start: addDays(todayStr, 38),
    allDay: true,
    classNames: `eventClass normal`,
    extendedProps: {
      isCheckpoint: true,
      desc: `By the end of the checkpoint, you will be able to evaluate viewport requirements, understand the power of emotion and touchpoints in visual design, and apply these methods to your own visual design process for your client app UI.`,
      url: `https://www.uxuiopen.com/docs/specialization_visual1/03/`,
      guides: [],
      activities: [],
      assignments: [],
    },
  },
  {
    id: createEventId(),
    title: '14.4 Designing Screens',
    start: addDays(todayStr, 38),
    allDay: true,
    classNames: `eventClass normal`,
    extendedProps: {
      isCheckpoint: true,
      desc: `By the end of the checkpoint, you will be able to continue designing the screens for your client app project and have deliverables to present to your client in the next checkpoint.`,
      url: `https://www.uxuiopen.com/docs/specialization_visual1/04/`,
      guides: [],
      activities: [],
      assignments: [],
    },
  },
  {
    id: createEventId(),
    title: '14.5 Testing Screens',
    start: addDays(todayStr, 38),
    allDay: true,
    classNames: `eventClass normal`,
    extendedProps: {
      isCheckpoint: true,
      desc: `By the end of the checkpoint, you will be able to test your high-fidelity mockups and incorporate user feedback into your design.`,
      url: `https://www.uxuiopen.com/docs/specialization_visual1/05/`,
      guides: [],
      activities: [{
        name: 'Testing using Maze',
        url: `https://www.uxuiopen.com/docs/specialization_visual1/05/#activity-testing-screens-`
      }],
      assignments: [],
    },
  },
  {
    id: createEventId(),
    title: '15 FrontEnd (Optional)',
    start: addDays(todayStr, 39),
    allDay: true,
    classNames: `eventClass optional`,
    extendedProps: {
      isCheckpoint: true,
      desc: `By the end of the checkpoint, you will have an understanding of the foundations of frontend development.`,
      url: `https://www.uxuiopen.com/docs/specialization_front1/01/`,
      guides: [],
      activities: [],
      assignments: [],
    },
  },
  {
    id: createEventId(),
    title: '16.1 User Research II',
    start: addDays(todayStr, 42),
    allDay: true,
    classNames: `eventClass gold`,
    extendedProps: {
      isCheckpoint: true,
      desc: `By the end of this checkpoint, you will be able to describe when and why to use a card sort as a research method. You will also create a site map of an existing website as the first step in your research.`,
      url: `https://www.uxuiopen.com/docs/specialization_user2/01/`,
      guides: [],
      activities: [],
      assignments: [],
    },
  },
  {
    id: createEventId(),
    title: '16.2 - 16.4',
    duration: '02:00',
    start: addDays(todayStr, 42),
    allDay: true,
    classNames: `eventClass normal`,
    extendedProps: {
      isCheckpoint: true,
      desc: `Complete going over lessons 16.2 to 16.4`,
      url: `https://www.uxuiopen.com/docs/specialization_user2/02/`,
      guides: [],
      activities: [{
          name: 'Planning Card Sort',
          url: `https://www.uxuiopen.com/docs/specialization_user2/02/#activity-`
        },
        {
          name: 'Running Card Sort',
          url: `https://www.uxuiopen.com/docs/specialization_user2/03/#activity-`
        },
        {
          name: 'Analysing Results',
          url: `https://www.uxuiopen.com/docs/specialization_user2/04/#activity-`
        }
      ],
      assignments: [],
    },
  },
  {
    id: createEventId(),
    title: '16.5 Illustrating',
    start: addDays(todayStr, 42),
    allDay: true,
    classNames: `eventClass gold`,
    extendedProps: {
      isCheckpoint: true,
      desc: `By the end of this checkpoint, you will be able to describe the card-sort research process and justify design decisions in a case study.`,
      url: `https://www.uxuiopen.com/docs/specialization_user2/05/`,
      guides: [],
      activities: [],
      assignments: [{
        name: 'Self Graded: Case study outline',
        url: `https://www.uxuiopen.com/docs/specialization_user2/05/#assignment-`
      }],
    },
  },
  {
    id: createEventId(),
    title: '17.2 Design Components',
    start: addDays(todayStr, 43),
    allDay: true,
    classNames: `eventClass gold`,
    extendedProps: {
      isCheckpoint: true,
      desc: `By the end of the checkpoint, you will be able to design components that fit the branding of LocalEyez. You will revisit the principles and elements of visual design and hone your skills as a T-shaped designer.`,
      url: `https://www.uxuiopen.com/docs/specialization_visual2/02/`,
      guides: [],
      activities: [],
      assignments: [{
        name: 'Self Graded',
        url: `https://www.uxuiopen.com/docs/specialization_visual2/02/#assignment-`
      }],
    },
  },
  {
    id: createEventId(),
    title: '17.1 Activities App',
    start: addDays(todayStr, 43),
    allDay: true,
    classNames: `eventClass normal`,
    extendedProps: {
      isCheckpoint: true,
      desc: `By the end of this checkpoint, you will be able to describe when and why to use a card sort as a research method. You will also create a site map of an existing website as the first step in your research.`,
      url: `https://www.uxuiopen.com/docs/specialization_visual2/01/`,
      guides: [],
      activities: [],
      assignments: [],
    },
  },
  {
    id: createEventId(),
    title: '17.3 & 17.4',
    start: addDays(todayStr, 43),
    allDay: true,
    classNames: `eventClass normal`,
    extendedProps: {
      isCheckpoint: true,
      desc: `By the end of the checkpoint, you will be able to design interactions for LocalEyez. You will also explore in-app animations using splash screens, loading screens, and more. Although you won't be animating, you will be provided with a list of additional resources for if you choose to learn animation`,
      url: `https://www.uxuiopen.com/docs/specialization_visual2/03/`,
      guides: [],
      activities: [],
      assignments: [],
    },
  },
  {
    id: createEventId(),
    title: '17.5 Review',
    start: addDays(todayStr, 43),
    allDay: true,
    classNames: `eventClass gold`,
    extendedProps: {
      isCheckpoint: true,
      desc: `By the end of the checkpoint, you will have completed all the steps to producing great UI for LocalEyez. You will then showcase your knowledge by writing a case study for your work.`,
      url: `https://www.uxuiopen.com/docs/specialization_visual2/05/`,
      guides: [],
      activities: [],
      assignments: [{
        name: 'Assignment 17: Case Study',
        url: `https://www.uxuiopen.com/docs/specialization_visual2/05/#assignment-17-`
      }],
    },
  },
  {
    id: createEventId(),
    title: 'Mentor Call 8',
    start: addDays(todayStr, 44),
    allDay: true,
    classNames: `eventClass mentor`,
    extendedProps: {
      isCheckpoint: true,
      desc: 'Visit ADP list and schedule your mentor call. Ensure you let the mentor know in advance the doubts you have. Jot down a few questions you have in mind that you would want to ask them. Share the uxuiopen link if necessary for them to know what doubts you have',
      url: `https://www.uxuiopen.com/docs/presenting/10/#mentor-call-6-`,
      activities: [],
      assignments: [],
    },
  },
  {
    id: createEventId(),
    title: '18 Front End II (Optional)',
    start: addDays(todayStr, 44),
    end: addDays(todayStr, 47),
    // allDay: true,
    classNames: `eventClass optional`,
    extendedProps: {
      isCheckpoint: true,
      desc: `This section introduces you to the world of programming. It's not a mandatory thing to learn to code to be a product designer but it can set you apart from the rest. Feel free to skip this section and return whenever you feel comfortable`,
      url: `https://www.uxuiopen.com/docs/category/18---front-end-design-ii/`,
      guides: [],
      activities: [],
      assignments: [],
    },
  },
  {
    id: createEventId(),
    title: 'CATCH UP',
    start: addDays(todayStr, 49),
    allDay: true,
    classNames: `eventClass green`,
    extendedProps: {
      isCheckpoint: true,
      desc: `Take rest. Catch up if you've fallen behind schedule.`,
      url: 'https://www.youtube.com/watch?v=dQw4w9WgXcQ',
      activities: [],
      assignments: [],
    },
  },
  {
    id: createEventId(),
    title: '19 User Research III',
    start: addDays(todayStr, 50),
    allDay: true,
    classNames: `eventClass gold`,
    extendedProps: {
      isCheckpoint: true,
      desc: `By the end of this checkpoint, you will be able to create a brief research plan for a usability testing project.`,
      url: `https://www.uxuiopen.com/docs/category/19---user-research-iii/`,
      guides: [],
      activities: [],
      assignments: [{
        name: 'Self Graded',
        url: `https://www.uxuiopen.com/docs/specialization_user3/05/#assignment-`
      }],
    },
  },
  {
    id: createEventId(),
    title: '20.1 Machine Learning App',
    start: addDays(todayStr, 50),
    allDay: true,
    classNames: `eventClass gold`,
    extendedProps: {
      isCheckpoint: true,
      desc: `By the end of the checkpoint, you will be able to evaluate machine-learning technology and apply it to the user interface you are being asked to design for this module.`,
      url: `https://www.uxuiopen.com/docs/specialization_visual3/01/`,
      guides: [],
      activities: [],
      assignments: [{
        name: 'Assignment 18',
        url: `https://www.uxuiopen.com/docs/specialization_visual3/01/#assignment-18-`
      }],
    },
  },
  {
    id: createEventId(),
    title: '20.2 Design Principles',
    start: addDays(todayStr, 50),
    allDay: true,
    classNames: `eventClass gold`,
    extendedProps: {
      isCheckpoint: true,
      desc: `By the end of the checkpoint, you will be able to evaluate design principles and apply them to the user interface you are being asked to design for this module.`,
      url: `https://www.uxuiopen.com/docs/specialization_visual3/02/`,
      guides: [],
      activities: [],
      assignments: [{
        name: 'Assignment 18 continue',
        url: `https://www.uxuiopen.com/docs/specialization_visual3/02/#assignment-18-continue-`
      }],
    },
  },
  {
    id: createEventId(),
    title: '20.4 Refining UI',
    start: addDays(todayStr, 51),
    allDay: true,
    classNames: `eventClass normal`,
    extendedProps: {
      isCheckpoint: true,
      desc: `By the end of the checkpoint, you will be able to take feedback and successfully integrate it into an existing design`,
      url: `https://www.uxuiopen.com/docs/specialization_visual3/04/`,
      guides: [],
      activities: [{
        name: 'Feedback changes',
        url: `https://www.uxuiopen.com/docs/specialization_visual3/04/#activity-`
      }],
      assignments: [],
    },
  },
  {
    id: createEventId(),
    title: '20.5 Wrap Up',
    start: addDays(todayStr, 51),
    allDay: true,
    classNames: `eventClass gold`,
    extendedProps: {
      isCheckpoint: true,
      desc: `By the end of the checkpoint, you will be able to showcase your knowledge by writing a case study for your work.`,
      url: `https://www.uxuiopen.com/docs/specialization_visual3/04/`,
      guides: [],
      activities: [],
      assignments: [{
        name: 'Wrap up',
        url: `https://www.uxuiopen.com/docs/specialization_visual3/05/#assignment-20-`
      }],
    },
  },
  {
    id: createEventId(),
    title: 'Mentor Call 9',
    start: addDays(todayStr, 51),
    allDay: true,
    classNames: `eventClass mentor`,
    extendedProps: {
      isCheckpoint: true,
      desc: 'Visit ADP list and schedule your mentor call. Ensure you let the mentor know in advance the doubts you have. Jot down a few questions you have in mind that you would want to ask them. Share the uxuiopen link if necessary for them to know what doubts you have',
      url: `https://www.uxuiopen.com/docs/presenting/10/#mentor-call-6-`,
      activities: [],
      assignments: [],
    },
  },
  {
    id: createEventId(),
    title: '18 Front End III (Optional)',
    start: addDays(todayStr, 52),
    end: addDays(todayStr, 54),
    // allDay: true,
    classNames: `eventClass optional`,
    extendedProps: {
      isCheckpoint: true,
      desc: `By the end of this checkpoint, you will start to understand how JavaScript is used within websites.`,
      url: `https://www.uxuiopen.com/docs/category/21---front-end-design-iii/`,
      guides: [],
      activities: [],
      assignments: [],
    },
  },
  {
    id: createEventId(),
    title: '18 Front End III (Optional)',
    start: addDays(todayStr, 56),
    end: addDays(todayStr, 60),
    // allDay: true,
    classNames: `eventClass optional`,
    extendedProps: {
      isCheckpoint: true,
      desc: `By the end of this checkpoint, you will start to understand how JavaScript is used within websites.`,
      url: `https://www.uxuiopen.com/docs/category/21---front-end-design-iii/`,
      guides: [],
      activities: [],
      assignments: [],
    },
  },
  {
    id: createEventId(),
    title: '22 Capstone: Discover',
    start: addDays(todayStr, 60),
    // end: addDays(todayStr, 61),
    allDay: true,
    classNames: `eventClass normal`,
    extendedProps: {
      isCheckpoint: true,
      desc: `It's finally time to build your own project. Every project you build in the program is critical to your success as a designer. Whether you are starting a business, joining a company, or contracting your design skills, your ability to solve problems through design will become your most important qualification. Each finished project should showcase this ability.`,
      url: `https://www.uxuiopen.com/docs/22/`,
      guides: [],
      activities: [],
      assignments: [],
    },
  },
  {
    id: createEventId(),
    title: '22 Capstone: Discover',
    start: addDays(todayStr, 63),
    end: addDays(todayStr, 66),
    // allDay: true,
    classNames: `eventClass normal`,
    extendedProps: {
      isCheckpoint: true,
      desc: `It's finally time to build your own project. Every project you build in the program is critical to your success as a designer. Whether you are starting a business, joining a company, or contracting your design skills, your ability to solve problems through design will become your most important qualification. Each finished project should showcase this ability.`,
      url: `https://www.uxuiopen.com/docs/22/`,
      guides: [],
      activities: [],
      assignments: [],
    },
  },
  {
    id: createEventId(),
    title: '23 Capstone: Develop',
    start: addDays(todayStr, 66),
    end: addDays(todayStr, 68),
    // allDay: true,
    classNames: `eventClass normal`,
    extendedProps: {
      isCheckpoint: true,
      desc: `It's finally time to build your own project. Every project you build in the program is critical to your success as a designer. Whether you are starting a business, joining a company, or contracting your design skills, your ability to solve problems through design will become your most important qualification. Each finished project should showcase this ability.`,
      url: `https://www.uxuiopen.com/docs/capstone_develop/01/`,
      guides: [],
      activities: [],
      assignments: [],
    },
  },
  {
    id: createEventId(),
    title: '23 Capstone: Develop',
    start: addDays(todayStr, 70),
    end: addDays(todayStr, 72),
    // allDay: true,
    classNames: `eventClass normal`,
    extendedProps: {
      isCheckpoint: true,
      desc: `It's finally time to build your own project. Every project you build in the program is critical to your success as a designer. Whether you are starting a business, joining a company, or contracting your design skills, your ability to solve problems through design will become your most important qualification. Each finished project should showcase this ability.`,
      url: `https://www.uxuiopen.com/docs/capstone_develop/01/`,
      guides: [],
      activities: [],
      assignments: [],
    },
  },
  {
    id: createEventId(),
    title: '24 Capstone: Deliver',
    start: addDays(todayStr, 72),
    // end: addDays(todayStr, 128),
    allDay: true,
    classNames: `eventClass normal`,
    extendedProps: {
      isCheckpoint: true,
      desc: `It's finally time to build your own project. Every project you build in the program is critical to your success as a designer. Whether you are starting a business, joining a company, or contracting your design skills, your ability to solve problems through design will become your most important qualification. Each finished project should showcase this ability.`,
      url: `https://www.uxuiopen.com/docs/capstone_develop/01/`,
      guides: [],
      activities: [],
      assignments: [],
    },
  },
  {
    id: createEventId(),
    title: 'Mentor Call 10',
    start: addDays(todayStr, 73),
    allDay: true,
    classNames: `eventClass mentor`,
    extendedProps: {
      isCheckpoint: true,
      desc: 'Visit ADP list and schedule your mentor call. Ensure you let the mentor know in advance the doubts you have. Jot down a few questions you have in mind that you would want to ask them. Share the uxuiopen link if necessary for them to know what doubts you have',
      url: `https://www.uxuiopen.com/docs/presenting/10/#mentor-call-6-`,
      activities: [],
      assignments: [],
    },
  },
  {
    id: createEventId(),
    title: '25 Capstone: Assessment',
    start: addDays(todayStr, 73),
    // end: addDays(todayStr, 130),
    allDay: true,
    classNames: `eventClass graded`,
    extendedProps: {
      isCheckpoint: true,
      desc: `Schedule your final bootcamp assessment`,
      url: `https://www.uxuiopen.com/docs/capstone_assessment/01/#assessment`,
      guides: [],
      activities: [],
      assignments: [],
    },
  },
  {
    id: createEventId(),
    title: '26.1 Refining Your Work',
    start: addDays(todayStr, 74),
    allDay: true,
    classNames: `eventClass normal`,
    extendedProps: {
      isCheckpoint: true,
      desc: `By the end of this checkpoint, you will add visual design and the remaining projects.`,
      url: `https://www.uxuiopen.com/docs/portfolio2/01/`,
      guides: [],
      activities: [],
      assignments: [],
    },
  },
  {
    id: createEventId(),
    title: '26.2 Handling NDAs',
    start: addDays(todayStr, 74),
    allDay: true,
    classNames: `eventClass normal`,
    extendedProps: {
      isCheckpoint: true,
      desc: `By the end of this checkpoint, you will be able to analyze and replicate how to present work that is protected by NDAs or other agreements.`,
      url: `https://www.uxuiopen.com/docs/portfolio2/02/`,
      guides: [],
      activities: [],
      assignments: [],
    },
  },
  {
    id: createEventId(),
    title: '26.3 User Testing',
    start: addDays(todayStr, 74),
    allDay: true,
    classNames: `eventClass normal`,
    extendedProps: {
      isCheckpoint: true,
      desc: `By the end of this checkpoint, you will user test your portfolio.`,
      url: `https://www.uxuiopen.com/docs/portfolio2/03/`,
      guides: [],
      activities: [],
      assignments: [],
    },
  },
  {
    id: createEventId(),
    title: 'Career Week',
    start: addDays(todayStr, 77),
    end: addDays(todayStr, 82),
    classNames: `eventClass normal`,
    extendedProps: {
      isCheckpoint: true,
      desc: `Now it's time for your job hunt. Breifly read the carrer checkpoints from Root Learn that'll help you land your new design job `,
      url: 'https://dashboard.rootlearn.com/c/day-1/',
      guides: [],
      activities: [],
      assignments: [],
    },
  },
  {
    id: createEventId(),
    title: 'GRADUATE 🥳',
    start: addDays(todayStr, 81),
    allDay: true,
    classNames: `eventClass graded`,
    extendedProps: {
      isCheckpoint: true,
      desc: `Give yourself a huge round of applause.`,
      url: `https://www.uxuiopen.com/docs/graduate/01/`,
      guides: [],
      activities: [],
      assignments: [],
    },
  },
]
