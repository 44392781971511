import './index.css'
import './App.css';
import DemoApp from './components/DemoApp';
import { Analytics } from '@vercel/analytics/react';
import { Route, Routes } from 'react-router-dom';
import Lottie from "lottie-react";
import animation from "./wave.json";

function App() {

  const style = {
    height: 550,
  };
  return (
   <>
   <Analytics />
   {/* <div className='banner'>Note: Add some note</div>  */}
    <div  className='mainSmall'>
      <p> Please view the calendar on a larger screen </p>
      <Lottie animationData={animation} style={style} />
    </div>
    <div className='main'>
      <Routes>
          <Route path='/' element={<DemoApp isDemo/>}/>
          <Route path='/:cohortCode' element={<DemoApp />}/>
      </Routes>
    </div>
   </>
  );
}

export default App;
